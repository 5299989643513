@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
--color-primary: #faa850;
--color-secondary: #28255f;
--color-white:#ffffff;
--color-gray:#f5f5f5;
--color-gray-50: #aaaaaa;
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #d4d4d7;
--color-gray-400: #e6e6e6;
--color-gray-500: #606161;
--color-gray-600: #3f3f40;
--color-gray-700: #404041;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--font-primary: "Poppins", sans-serif;
}
/*==========================================================*/
/*==========================================================*/
body{
font-family: var(--font-primary) !important;
font-size: 13px !important;
}
a {
text-decoration: none !important;
color: inherit !important;
transition: color 0.3s;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
font-weight: 500 !important;
line-height: 1.5 !important;
}
hr{
border-top:1px solid #ddd !important;
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
box-shadow: none !important;
outline: transparent;
}
/*==========================================================*/
.top-header{
width:100%;
position:relative;
padding:5px 0px;
border-bottom:1px solid var(--color-gray);
background:var(--color-primary);
}
.header-social{
width:100%;
position:relative;
}
.header-social ul{
margin:0;
padding:0;
list-style:none;
display:flex;
align-items:center;
justify-content:flex-end;
}
.header-social ul li a{
color: #fff !important;
margin-left: 10px;
}
.header{
width:100%;
position: relative;
z-index: 999;
background:var(--color-white);
display:flex;
align-items:center;
height:70px;
box-shadow:0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.fixed-head{
background: var(--color-white);
transform: translateY(40px);
top: -40px;
position: fixed;
left: 0;
z-index: 999;
}
.header .container, .header .container-fluid{
display: flex;
align-items: center;
}
.header-center, .header-left, .header-right {
display: flex;
align-items: center;
}
.header-left {
flex: 1;
}
.header-right {
flex: 1;
}
.header-left .logo{
margin-right: 75px;
max-width: 200px;
display: block;
}
.header-left .header-search {
position: relative;
flex: 1;
margin-right: 2rem;
max-width: 500px;
}
.header-search .input-wrapper {
display: flex;
position: relative;
}
.header-search input{
padding: 10px;
border-radius: 5px;
border: 2px solid var(--color-primary);
font-family: inherit;
background-color: #fff;
color: #999;
flex: 1;
font-size: 14px;
letter-spacing: inherit;
height:45px;
}
.header-search .btn-search {
position: absolute;
background: transparent;
color: #333;
min-width: 48px;
height: 100%;
border-radius: 5px;
right: 0;
padding: 0;
border: 0;
}
.header-search .btn-search i {
margin: 5px;
vertical-align: middle;
font-size: 18px;
}
.header-right .search-link {
margin-right: 20px;
}
.search-link i {
font-size: 22px;
}
.mheader-right .search-link {
margin-left: 15px;
}
.mheader-right .search-link i {
font-size: 18px;
}
.header-right .login-link {
margin-right: 20px;
}
.login-link i {
font-size: 24px;
}
.mheader-right .login-link {
margin-left: 15px;
}
.mheader-right .login-link i {
font-size: 20px;
}
.header-right .wishlist {
margin-right: 20px;
}
.wishlist i {
font-size: 25px;
}
.header-right .cart-toggle{
margin-right: 20px;
position: relative;
}
.mheader-right .cart-toggle{
margin-left: 15px;
position: relative;
}
.cart-toggle i {
font-size: 24px;
}
.mheader-right .cart-toggle i{
font-size: 20px;
}
.header-right .cart-toggle .cart-count{
position: absolute;
border-radius: 50%;
right: -10px;
top: -2px;
background: var(--color-primary);
width: 18px;
height: 18px;
font-size: 11px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
line-height: 18px;
}
.mheader-right .cart-toggle .cart-count{
position: absolute;
border-radius: 50%;
right: -10px;
top: -8px;
background: var(--color-primary);
width: 18px;
height: 18px;
font-size: 11px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
line-height: 18px;
}
.mheader-search{
width:100%;
position: relative;
}
.mheader-search input{
width:100%;
height: 59px;
border: 0px;
padding: 0px 30px;
}
.mheader-search .mheader-search-icon{
position: absolute;
top: 15px;
font-size: 18px;
}
.mheader-search .mheader-search-close{
position: absolute;
top: 15px;
font-size: 18px;
right: 0px;
}
.msearch-list{
width:100%;
position: relative;
}
.msearch-list ul{
margin: 0;
padding: 0;
list-style: none;
}
.msearch-list ul li{
display: flex;
align-items: center;
height: 35px;
padding: 0px 15px;
}
.navbar{
position:static !important;
}
.nav-link{
padding: 1.5rem 1rem !important;
font-size: 15px !important;
}
.dropdown-hover:hover>.dropdown-menu {
display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
/*Without this, clicking will make it sticky*/
pointer-events: none;
}
.submenu{
border: 0px !important;
background:var(--color-gray);
margin:0 !important;
min-width: 15rem;
padding:0 !important;
border-radius:0px !important;
}
.submenu .dropdown-item {
display: block;
width: 100%;
padding: 10px 15px;
clear: both;
font-weight: 400;
color: #212529;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
font-size:14px;
}
.mega-droupdown{
position:static !important;
}
.mega-menu{
width:100%;
left:0;
border: 0px !important;
margin:0;
padding:25px 0px !important;
background:var(--color-gray) !important;
border-radius:0px !important;
}
.mega-menu .container{
display:block !important;
}
.mmlist{
width:100%;
position:relative;
}
.mmlist-title a{
display:inline-block;
font-size:15px;
font-weight:600;
margin-bottom:5px;
}
.mmlist ul{
margin:0;
padding:0;
list-style:none;
}
.mmlist ul li{
display:inline-block;
width:100%;
position:relative;
}
.mmlist ul li a{
font-size:13px;
line-height:26px;
display:inline-block;
}
.mmlist ul li a:hover{
color:var(--color-primary) !important;
}
/*==========================================================*/
.home-slider img{
width: 100%;
position: relative;
object-fit: cover;
}
.mhome-slider img{
width: 100%;
height: 100%;
position: relative;
object-fit: cover;
}
/*==========================================================*/
figure{
margin: 0;
}
.tx-theme{
color:var(--color-primary) !important;
}
.tx-primary{
color:var(--color-primary) !important;
}
.tx-gray{
color:var(--color-gray-50) !important;
}
.tx-green{
  color:#26a537;
  }
.tx-11{
font-size:11px;
}
.tx-12{
font-size:12px;
}
.tx-13{
font-size:13px;
}
.tx-14{
font-size:14px;
}
.tx-15{
font-size:15px;
}
.tx-16{
font-size:16px;
}
.tx-18{
font-size:18px;
}
.tx-center{
  text-align: center;
}
.fw600{
font-weight: 600 !important;
}
.tx-color-02{
color:var(--tx-color-02);
}
.tx-color-03{
color:var(--tx-color-03);
}
.text-right{
text-align: right;
}
.btn01{
height: 50px;
}
.btn-block{
width:100%;
}
.btn-primary{
background: var(--color-primary) !important;
border-color: var(--color-primary) !important;
color:#fff !important;
}
.btn-primary-outline{
background:transparent !important;
border-color: var(--color-primary) !important;
color:var(--color-primary) !important;
}
.btn-primary-outline:hover{
background: var(--color-secondary) !important;
border-color: var(--color-secondary) !important;	
color:var(--color-white) !important;
}
.btn{
border-radius:5px !important;
}
.btn-small{
font-size:13px !important;
padding:13px 12px 10px !important;
}
.btn-medium{
font-size:14px !important;
padding:13px 15px 10px !important;
}
.btn-large{
font-size:16px !important;
padding:15px 30px !important;
}
figure{
margin: 0 !important;
}
.main{
display: block;
position: relative;
}
.section-title{
width:100%;
position:relative;
}
.section-title h2{
font-size:62px;
}
.section-title h3{
font-size:22px;
}
.section-title h4{
font-size:24px;
text-transform:uppercase;
}
.section-title h5{
font-size:20px;
text-transform:uppercase;
}
.page-title{
width:100%;
position: relative;
}
.page-title h2{
font-size: 24px;
text-transform:uppercase;
}
.page-title-line{
width:100%;
position:relative;
}
.page-title-line:after{
content: "";
width: 100%;
height: 1px;
background: var(--color-gray-dark);
position: absolute;
bottom: -10px;
left: 0;
}
.page-title-line h2{
font-size: 20px;
text-transform: uppercase;
}
.sec-gap-top{
padding:60px 0px 0px 0px;
}
.sec-gap-small{
padding:30px 0px;
}
.sec-gap-medium{
padding:60px 0px;
}
.sec-gap-large{
padding:80px 0px;
}
.radius10{
border-radius:10px;
}
.spacer1{
width:100%;
position:relative;
height:1px;
background:var(--color-gray);
}
.spacer3{
width:100%;
position:relative;
height:3px;
background:var(--color-gray);
}
.spacer5{
width:100%;
position:relative;
height:5px;
background:var(--color-gray);
}
.spacer10{
width:100%;
position:relative;
height:10px;
background:var(--color-gray);
}
.wd-28 {
width: 28px !important;
}
.wd-38 {
  width: 38px !important;
  }
.wd-42 {
  width: 42px !important;
  }
/*==========================================================*/
@media (max-width: 991px){
.dm-none {
display: none;
}
.section-title h2{
font-size:32px;
}
.section-title h3{
font-size:18px;
}
.sec-gap-small{
padding:10px 0px;
}
.sec-gap-medium{
padding:30px 0px;
}
.sec-gap-large{
padding:40px 0px;
}

}
/*=========================================================*/
.catslider .owl-item .cathbox {
transform: translate3d(0, 0, 0); 
transform: scale(1.1);
transition: all .25s ease-in-out; 
}
.catslider .owl-item .cathbox img {
-webkit-transition: 0.3s;
-webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
-o-transition: 0.3s;
transition: 0.3s;
-webkit-transform: scale(0.80);
-ms-transform: scale(0.80);
transform: scale(0.80);
border-radius:10px;
}
.catslider .owl-item.center .cathbox img {
-webkit-transform: scale(1.15);
-ms-transform: scale(1.15);
transform: scale(1.15);
}
.cathbox{
width:100%;
position:relative;
}
.cathbox .cathbox-media{
width:100%;
position:relative;
}
.cathbox-name{
position: absolute;
bottom: 2rem;
z-index: 9999;
left: 0;
width: 100%;
text-align: center;
transform: translate3d(0, 0, 0);
transform: scale(0.7);
transition: all .25s ease-in-out;
background:rgba(104,66,135,0.8);
padding:20px 10px;
border-radius:10px;
font-size: 24px;
color: #fff;
}
.catslider .owl-item.center .cathbox-name{
-webkit-transform: scale(0.6);
-ms-transform: scale(0.6);
transform: scale(0.6);
bottom: 4rem;
}
/*===========================================================*/

.catSwiper .swiper-slide {
height: 450px;
transform: scale(0.9) !important;
}
.catSwiper .swiper-slide-active{
transform: scale(1) !important;
}
.catSwiper .swiper-slide img{
width:100%;
height:450px;
object-fit:cover;
border-radius:10px;
}
/*===========================================================*/
.overlay-zoom, .overlay-zoom figure {
overflow: hidden;
}


.overlay-zoom img {
transition: transform 0.3s;
}
.overlay-zoom:hover img {
transform: scale(1.08);
}
/*==========================================================*/
.couponModal .modal-dialog{
max-width: 450px;
}
.couponModal .couponModal-header{
padding:25px 25px 10px 25px;
width:100%;
position:relative;
color:#333;
}
.couponModal .couponModal-search{
width:100%;
position:relative;
padding:0px 25px;
}
.couponModal-search input{
width:100%;
height:45px;
border:1px solid #ddd;
border-radius:5px;
padding:0px 15px;
}
.couponModal .couponModal-search button{
position: absolute;
top: 0;
right: 26px;
height: 43px;
background: transparent;
border: 0px;
width: 75px;
}
.apply-coupons-list{
width:100%;
position:relative;
color:#333;
}
.apply-coupons-list ul{
margin:0;
padding:0;
list-style:none;
}
.apply-coupons-list ul li{
display:inline-block;
width:100%;
position:relative;
padding:10px 25px;
}
.lrpop .modal-dialog{
max-width: 400px;
}
.lrpop .modal-content{
border: 0px;
}
.lrpop .modal-body{
width: 100%;
position: relative;
padding: 55px 35px 30px;
}
.lrpop .pop-close{
box-sizing: content-box;
width: 1em;
height: 1em;
padding: .25em .25em;
color: #000;
background: transparent;
border: 0;
border-radius: .25rem;
opacity: .5;
position: absolute;
top:15px;
right: 20px;
cursor: pointer;
z-index: 9999;
}
.lrpop .pop-close i{
font-size:22px;
}
.form-group .form-control {
border: 1px solid #eee;
height: 50px;
padding: 0px 15px;
border-radius: 5px;
font-size: 14px;
}
.form-group textarea{
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}
.form-control::-moz-placeholder {
color:#6c757d !important;
opacity:1
}
.form-control::placeholder {
color:#6c757d !important;
opacity:1
}
@media (max-width: 991px){
.lrpop .modal-dialog{
margin: 0;
height: 100%;
}
.lrpop .modal-content{
border-radius: 0px;
height: 100%;
}
.couponModal .modal-dialog{
margin: 0;
height: 100%;
max-width: 100%;
}
.couponModal .modal-content{
border-radius: 0px;
height: 100%;
border: 0px;
}
}
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
@font-face {
font-family:"riode";
src:url("../public/fonts/riode.eot?5gap68");
src:url("../public/fonts/riode.eot?5gap68#iefix") format("embedded-opentype"),
url("../public/fonts/riode.ttf?5gap68") format("truetype"),
url("../public/fonts/riode.woff?5gap68") format("woff");
font-weight:normal;
font-style:normal;
font-display:block
}
[class*=" d-icon-"],
[class^=d-icon-] {
display:inline-block;
line-height:1
}
[class*=" d-icon-"]:before,
[class^=d-icon-]:before {
font-family:"riode"!important;
speak:never;
font-style:normal;
font-weight:normal;
font-variant:normal;
text-transform:none;
-webkit-font-smoothing:antialiased;
-moz-osx-font-smoothing:grayscale
}
.d-icon-truck:before {
content:""
}
.d-icon-service:before {
content:""
}
.d-icon-secure:before {
content:""
}
.d-icon-lock:before {
content:""
}
.d-icon-percent:before {
content:""
}
.d-icon-layer:before {
content:""
}
.d-icon-alert:before {
content:""
}
.d-icon-database:before {
content:""
}
.d-icon-money:before {
content:""
}
.d-icon-shoppingbag:before {
content:""
}
.d-icon-t-shirt1:before {
content:""
}
.d-icon-t-shirt2:before {
content:""
}
.d-icon-hat:before {
content:""
}
.d-icon-officebag:before {
content:""
}
.d-icon-handbag:before {
content:""
}
.d-icon-backpack:before {
content:""
}
.d-icon-shoes:before {
content:""
}
.d-icon-pillow:before {
content:""
}
.d-icon-dress:before {
content:""
}
.d-icon-loungewear:before {
content:""
}
.d-icon-desktop:before {
content:""
}
.d-icon-laptop:before {
content:""
}
.d-icon-gamepad1:before {
content:""
}
.d-icon-wireless:before {
content:""
}
.d-icon-projector:before {
content:""
}
.d-icon-drone1:before {
content:""
}
.d-icon-drone2:before {
content:""
}
.d-icon-radio:before {
content:""
}
.d-icon-battery:before {
content:""
}
.d-icon-plugin:before {
content:""
}
.d-icon-memory:before {
content:""
}
.d-icon-ball:before {
content:""
}
.d-icon-basketball1:before {
content:""
}
.d-icon-babycare:before {
content:""
}
.d-icon-card:before {
content:""
}
.d-icon-gamepad2:before {
content:""
}
.d-icon-camera1:before {
content:""
}
.d-icon-camera2:before {
content:""
}
.d-icon-babywear:before {
content:""
}
.d-icon-abacus:before {
content:""
}
.d-icon-pot:before {
content:""
}
.d-icon-freezer:before {
content:""
}
.d-icon-cook:before {
content:""
}
.d-icon-student:before {
content:""
}
.d-icon-category:before {
content:""
}
.d-icon-basketball2:before {
content:""
}
.d-icon-watch:before {
content:""
}
.d-icon-tcard:before {
content:""
}
.d-icon-heartbeat:before {
content:""
}
.d-icon-watch-round:before {
content:""
}
.d-icon-washbowl:before {
content:""
}
.d-icon-bridge-lamp:before {
content:""
}
.d-icon-lamp:before {
content:""
}
.d-icon-sofa:before {
content:""
}
.d-icon-sofa2:before {
content:""
}
.d-icon-bed:before {
content:""
}
.d-icon-table:before {
content:""
}
.d-icon-table-lamp:before {
content:""
}
.d-icon-table-tv:before {
content:""
}
.d-icon-mirror:before {
content:""
}
.d-icon-volume:before {
content:""
}
.d-icon-bars2:before {
content:""
}
.d-icon-bars:before {
content:""
}
.d-icon-phone:before {
content:""
}
.d-icon-user:before {
content:""
}
.d-icon-search:before {
content:""
}
.d-icon-search2:before {
content:""
}
.d-icon-bag:before {
content:""
}
.d-icon-map:before {
content:""
}
.d-icon-info:before {
content:""
}
.d-icon-refresh:before {
content:""
}
.d-icon-left-arrow:before {
content:""
}
.d-icon-right-arrow:before {
content:""
}
.d-icon-down-arrow:before {
content:""
}
.d-icon-up-arrow:before {
content:""
}
.d-icon-ruler:before {
content:""
}
.d-icon-zoom:before {
content:""
}
.d-icon-right-circle:before {
content:""
}
.d-icon-left-circle:before {
content:""
}
.d-icon-up-circle:before {
content:""
}
.d-icon-down-circle:before {
content:""
}
.d-icon-angle-right:before {
content:""
}
.d-icon-angle-left:before {
content:""
}
.d-icon-angle-up:before {
content:""
}
.d-icon-angle-down:before {
content:""
}
.d-icon-star:before {
content:""
}
.d-icon-star-full:before {
content:""
}
.d-icon-heart:before {
content:""
}
.d-icon-heart-full:before {
content:""
}
.d-icon-close:before {
content:""
}
.d-icon-play-circle:before {
content:""
}
.d-icon-home:before {
content:""
}
.d-icon-filter-2:before {
content:""
}
.d-icon-switch:before {
content:""
}
.d-icon-switch-left-on:before {
content:""
}
.d-icon-switch-right-on:before {
content:""
}
.d-icon-filter-3:before {
content:""
}
.d-icon-check:before {
content:""
}
.d-icon-alert-solid:before {
content:""
}
.d-icon-headphone:before {
content:""
}
.d-icon-mode-grid:before {
content:""
}
.d-icon-mode-list:before {
content:""
}
.d-icon-cancel:before {
content:""
}
.d-icon-instagram:before {
content:""
}
.d-icon-solid-check:before {
content:""
}
.d-icon-play-solid:before {
content:""
}
.d-icon-mobile:before {
content:""
}
.d-icon-minus:before {
content:""
}
.d-icon-plus:before {
content:""
}
.d-icon-wifi:before {
content:""
}
.d-icon-times:before {
content:""
}
.d-icon-times-circle:before {
content:""
}
.d-icon-random:before {
content:""
}
.d-icon-th-list:before {
content:""
}
.d-icon-rocket:before {
content:""
}
.d-icon-map-marker:before {
content:""
}
.d-icon-birthday-cake:before {
content:""
}
.d-icon-gift:before {
content:""
}
.d-icon-female:before {
content:""
}
.d-icon-clock:before {
content:""
}
.d-icon-comments:before {
content:""
}
.d-icon-rotate-left:before {
content:""
}
.d-icon-rotate-right:before {
content:""
}
.d-icon-reading:before {
content:""
}
.d-icon-feeder:before {
content:""
}
.d-icon-birthday-cake2:before {
content:""
}
.d-icon-skirt:before {
content:""
}
.d-icon-toy:before {
content:""
}
.d-icon-butterfly:before {
content:""
}
.d-icon-babycare2:before {
content:""
}
.d-icon-butterfly2:before {
content:""
}
.d-icon-dinner-set:before {
content:""
}
.d-icon-ball2:before {
content:""
}
.d-icon-apple:before {
content:""
}
.d-icon-icecream:before {
content:""
}
.d-icon-cake:before {
content:""
}
.d-icon-meat:before {
content:""
}
.d-icon-bread:before {
content:""
}
.d-icon-cocktail:before {
content:""
}
.d-icon-food:before {
content:""
}
.d-icon-birthday-cake3:before {
content:""
}
.d-icon-cash:before {
content:""
}
.d-icon-service2:before {
content:""
}
.d-icon-car:before {
content:""
}
.d-icon-ball3:before {
content:""
}
.d-icon-compare:before {
content:""
}
.d-icon-jar:before {
content:""
}
.d-icon-radish:before {
content:""
}
.d-icon-arrow-down:before {
content:""
}
.d-icon-arrow-left:before {
content:""
}
.d-icon-arrow-right:before {
content:""
}
.d-icon-arrow-up:before {
content:""
}
.d-icon-earth:before {
content:""
}
.d-icon-long-arrow-left:before {
content:""
}
.d-icon-long-arrow-right:before {
content:""
}
.d-icon-long-arrow-down:before {
content:""
}
.d-icon-long-arrow-up:before {
content:""
}
.d-icon-pill:before {
content:""
}
.d-icon-cylinder:before {
content:""
}
.d-icon-medical-bag:before {
content:""
}
.d-icon-graph:before {
content:""
}
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
.product{
width:100%;
position: relative;
transition: 0.3s;
overflow: hidden;
}
.product .product-media{
display: block;
overflow: hidden;
position: relative;
}
.product .product-media::before {
content: "";
display: block;
padding-top: 130%;
}
.product .product-media-img {
height: 100%;
left: 0;
position: absolute;
top: 0;
transition: box-shadow .3s;
width: 100%;
}
.product .product-media-img img {
height: 100%;
object-fit: cover;
width: 100%;
}
.product .product-media-img:hover img:first-child {
opacity:0
}
.product .product-media-img:hover img:last-child {
opacity:1
}
.product .product-media img:last-child {
position:absolute;
opacity:0;
left:0;
right:0;
top:0;
left:0;
object-fit:cover
}
.product .product-media img:first-child {
position:relative;
opacity:1
}
.product:hover .product-action {
visibility:visible;
opacity:1
}
.product-action {
position: absolute;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
background-color: transparent;
z-index: 10;
transition: opacity 0.3s,visibility 0.3s;
opacity: 0;
}
.btn-product {
display:flex;
align-items:center;
justify-content:center;
flex:1;
padding:1.14em 0;
background-color:var(--color-secondary);
color:#fff !important;
font-size:14px;
font-weight: 500;
line-height:1.2;
text-transform:uppercase;
transition:opacity 0.3s;
}
.btn-product:hover {
color:#fff !important;
}
.btn-quickview {
opacity:0.8;
transition:opacity 0.3s
}
.btn-quickview:hover {
opacity:1
}
.btn-quickview i {
font-size:1.4rem
}
.product-action-vertical {
position:absolute;
top:10px;
right:10px;
transition:opacity 0.3s,visibility 0.3s;
}
.btn-product-icon {
display:flex;
align-items:center;
justify-content:center;
width:34px;
height:34px;
margin-bottom:0.5rem;
border:0px solid #e1e1e1;
border-radius:50%;
background-color:rgba(255, 255, 255, 0.5);
color:#999;
font-size:16px;
font-weight:700;
transition:border-color 0.3s,color 0.3s,background-color 0.3s
}
.btn-product-icon:hover {
border-color:var(--color-primary);
color:#fff !important;
background-color:var(--color-primary)
}
.product-label-group {
display: flex;
flex-direction: column;
position: absolute;
top: 10px;
max-width: 10rem;
left:10px;
}
.product-label {
display: inline-block;
text-align: center;
margin-bottom: 0.5rem;
padding: 4px 5px 4px;
font-size: 10px;
border-radius: 0px;
font-weight: 500;
line-height: 1;
color: #fff;
text-transform: uppercase;
}
.product-label.label-new {
background:var(--color-primary);
}
.product-label.label-sale {
background:#d26e4b
}
.product-label.label-top {
background:#26c
}
.product-label.label-stock {
background:#999
}
.product-details {
position: relative;
padding-top: 15px;
}
.product-details .product-details-inner{
width:100%;
position:relative;
height: auto;
}
.product-name {
margin-bottom: 0.3rem;
font-family: inherit;
font-size: 13px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
height: 42px;
}
.product-price {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: block;
align-items: center;
flex-wrap: wrap;
margin-bottom: 0.3rem;
font-size: 14px;
font-weight: 600;
line-height: 1.86;
color: #222;
}
.product-price .new-price {
margin-right:7px;
text-decoration: none;
}
.product-price .old-price {
color: #aaa;
margin-right:7px;
font-size: 14px;
}
.product-price .off {
color: #388e3c;
font-size: 14px;
}
.ratings-container {
display: flex;
align-items: center;
margin-left: 1px;
line-height: 1.1;
margin-bottom: 1.2rem;
font-size: 12px;
letter-spacing: 0.3em;
color: #aaa;
}
.ratings-container .fill{
color:#ffa41c;
}
.ratings-container span{
letter-spacing: 0;
margin-left: 10px;
font-size: 12px;
}
@media (max-width: 991px){
.product-name {
font-size: 12px;
/* height: 35px; */
}
}
/*==================================================*/
.shortby {
width: 100%;
position: relative;
display: flex;
align-items: center;
margin-bottom: 20px;
}
.productcount{
font-size:12px;
}
.shortbydrop{
margin-left:auto;
}
.shortbydrop .shortbydrop-inner{
display:flex;
align-items:center;
}
.shortbydrop label{
margin-right:10px;
}
.shortbydrop select {
appearance: none;
outline: 0;
border: 0;
box-shadow: none;
/* Personalize */
flex: 1;
padding: 0 1em;
color: #000;
background-color: var(--color-gray);
background-image: none;
cursor: pointer;
height: 40px;
border-radius:5px 0px 0px 5px;
}
/* Remove IE arrow */
.shortbydrop select::-ms-expand {
display: none;
}
/* Custom Select wrapper */
.select {
position: relative;
display: flex;
width: 17em;
height: 40px;
border-radius:0px 5px 5px 0px;
overflow: hidden;
}
/* Arrow */
.select::after {
content: "";
position: absolute;
top: 0;
right: 0;
background-color:var(--color-gray);
transition: .25s all ease;
pointer-events: none;
font-family: "riode" !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
height:40px;
width:40px;
display:flex;
align-items:center;
justify-content:center;
}
/* Transition */
.select:hover::after {
color:var(--color-primary);
}


.shortbylist {
margin-left: auto;
}
.shortbylist ul{
margin:0;
padding:0;
list-style:none;
display:flex;
align-items:center;
}
.shortbylist ul li {
position: relative;
margin-left:7px;
font-size: 12px;
}
.shortbylist ul li a{
background:var(--color-gray);
padding: 5px 10px;
border-radius: 5px;
font-size: 12px;
color:#fff;
}
.shortbylist ul li.active a {
color: #333;
background:var(--color-primary);
border: 1px solid var(--color-primary);
}
.shortby-text{
display:block;
}
/*=====================================*/
.parentDisable{
position: fixed;
top: 0;
left: 0;
opacity: 0.8;
z-index: 998;
height: 100%;
width: 100%;
}
.overlay-box {
position:absolute;
top:50%;
left:50%;
transform:translate(-50%, -50%);
color: white; 
opacity: .8;
z-index: 1000;
}
/*==================================================*/
.page-content-inner{
margin-top:70px;
}
.subheader{
background: #f5f5f5;
padding: 10px 0px;
}
.subheader h1{
font-size:24px;
}
.subheader .breadcrumb{
margin: 0;
}
/*==================================================*/
.macc-profile{
background: var(--color-black);
width: 100%;
position: relative;
padding:15px;
display:flex;
align-items:center;
border-bottom: 2px solid var(--color-gray);
}
.macc-profile .macc-img{
width:50px;
height:50px;
background: var(--color-black-light-02);
border-radius:50%;
}
.macc-profile .macc-img img{
width:50px;
height:50px;
border-radius:50%;
object-fit:cover;
}
.macc-profile .macc-content{
flex:1;
margin-left:15px;
}
.macc-list{
width:100%;
position:relative;
background: var(--color-black);
border-bottom: 2px solid var(--color-gray-dark);
}
.macc-list ul{
margin:0;
padding:0;
list-style:none;
}
.macc-list ul li{
display:flex;
align-items:center;
font-size:14px;
font-weight:600;
height:65px;
padding:0px 15px;
border-bottom: 1px solid var(--color-gray-dark);
}
.macc-list ul li a{
display:flex;
align-items:center;
width: 100%;	
}
.macc-list .macc-list-icon{
width: 36px;
height: 36px;
background: var(--color-gray-dark);
margin-right: 15px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.macc-list .macc-list-icon img{
width:18px;
height:18px;
}
.macc-list i{
margin-left:auto;
font-size:18px;
}
.logout-button{
background:var(--color-white);
padding:20px;
text-align:center;
color:#fff;
}
.logout-logo{
width:120px;
}
.helpbox{
width:100%;
position:relative;
border-bottom: 3px solid var(--color-gray);	
}
/*==================================================*/
.account-user{
background: #fff;
box-shadow: rgba(33,33,33,.06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
padding:15px;
display:flex;
align-items:center;
}
.account-user .au-imag{
width:50px;
height:50px;
background:#f5f5f5;
border-radius:50%;
}
.account-user .au-imag img{
width:50px;
height:50px;
border-radius:50%;
object-fit:cover;
}
.account-user .au-content{
flex:1;
margin-left:15px;
}
.aclist{
background: #fff;
box-shadow: rgba(33,33,33,.06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
padding:15px 20px;
}
.aclist ul{
margin:0;
padding:0;
list-style:none;
}
.aclist ul li{
display:inline-block;
width:100%;
font-weight:600;
}
.aclist ul li a{
display:flex;
align-items:center;
padding:7px 0px;
justify-content:space-between;
}
.aclist ul li.active a{
color:#793b00 !important;
}
.acpanel{
background: #fff;
box-shadow: rgba(33,33,33,.06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
}
.acpanel .acpanel-header{
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
padding:20px;
border-bottom: 1px solid #eee;
}
.acpanel .acpanel-header h4{
margin-bottom:0px;
font-size:20px;
}
.acpanel .acpanel-body{
padding:20px;
width:100%;
position:relative;
}
/*==================================================*/
.addressbox{
width:100%;
position: relative;
background: #fff;
border: 1px solid #eee;
border-radius: 5px;
}
.addressbox .addressbox-body{
width:100%;
position: relative;
padding: 15px;
}
.addressbox .addressbox-footer{
display: flex;
align-items: center;
padding: 5px 15px 15px;
}
.addressbox .addressbox-footer a{
margin-right: 20px;
color:#6d1a17;
font-weight: 600;
}
.addressbox-type{
position: absolute;
top: 15px;
right: 15px;
background: #f5f5f5;
padding: 2px 10px;
border-radius: 10px;
text-transform: uppercase;
font-size: 11px;
font-weight: 600;
}
/*==================================================*/
.morderbox{
width:100%;
position: relative;
background: #fff;
display: inline-block;
}
.morderbox .morderbox-body{
padding: 15px;
}
.morderbox .morderbox-footer{
display: flex;
align-items: center;
justify-content: space-between;
padding: 5px 15px 15px;
}
.morderbox-link{
font-size: 12px;
}
.morderbox-status{
font-size: 12px;
padding: 5px 10px;
border-radius: 5px;
}
.maddressbox{
width:100%;
position: relative;
background: #fff;
display: inline-block;
}
.maddressbox .maddressbox-body{
padding: 15px 75px 15px 15px;
}
.maddressbox .maddressbox-footer{
display: flex;
align-items: center;
padding: 5px 15px 15px;
}
.maddressbox .maddressbox-footer a{
margin-right: 20px;
color:#6d1a17;
font-weight: 600;
}
.maddress-header{
padding: 10px 15px;
width:100%;
font-weight: 600;
}
.maddressbox-type{
position: absolute;
top: 15px;
right: 15px;
background: #f5f5f5;
padding: 2px 10px;
border-radius: 10px;
text-transform: uppercase;
font-size: 11px;
font-weight: 600;
}
/*=====================================================*/
.product-single .product-name {
font-size:26px;
font-weight: 600 !important;
white-space: normal;
margin-bottom:10px;
}
.product-single .product-meta {
margin-bottom:10px;
color: #999;
font-size: 12px;
}
.product-single .product-meta span {
margin: 0 1.9rem 0 3px;
}
.product-single .product-price{
font-size: 18px;
font-weight: 600;
}
.product-single .product-price .new-price {
margin-right: 7px;
text-decoration: none;
}
.product-single .product-price .old-price {
color: #aaa;
margin-right: 7px;
font-size: 22px;
}
.product-single .product-price .off {
color: #388e3c;
font-size: 22px;
}
.product-single .product-footer{
display:flex;
align-items:center;
}
.product-single .social-links {
color:#999;
}
.product-single .social-link {
border:none;
font-size:18px;
}
.product-single .social-link.social-facebook:hover {
background-color:transparent;
color:#3b5998
}
.product-single .social-link.social-twitter:hover {
background-color:transparent;
color:#1da1f2
}
.product-single .social-link.social-pinterest:hover {
background-color:transparent;
color:#bd081c
}
.product-single .product-footer>* {
margin-bottom:1rem
}
.social-link {
margin:2px 8px 2px 0;
width:30px;
height:30px;
line-height:28px;
font-size:1.5rem;
border-radius:50%;
border:2px solid #ccc;
color:inherit;
transition:color 0.4s,border 0.4s,background 0.4s;
text-align:center
}
.social-link:last-child {
margin-right:0
}
.social-link i {
letter-spacing:-0.001em;
line-height:1
}
.social-link:hover {
background:#26c;
border-color:#26c;
color:#fff
}
.social-link.social-facebook:hover {
background:#3b5998;
border-color:#3b5998
}
.social-link.social-twitter:hover {
background:#1da1f2;
border-color:#1da1f2
}
.social-link.social-linkedin:hover {
background:#0073b2;
border-color:#0073b2
}
.social-link.social-email:hover {
background:#dd4b39;
border-color:#dd4b39
}
.social-link.social-google:hover {
background:#dd4b39;
border-color:#dd4b39
}
.social-link.social-pinterest:hover {
background:#bd081c;
border-color:#bd081c
}
.social-link.social-reddit:hover {
background:#ff4107;
border-color:#ff4107
}
.social-link.social-tumblr:hover {
background:#304e6c;
border-color:#304e6c
}
.social-link.social-vk:hover {
background:#6383a8;
border-color:#6383a8
}
.social-link.social-youtube:hover {
background:#26c;
border-color:#26c
}
.social-link.social-whatsapp:hover {
background:#3c8a38;
border-color:#3c8a38
}
.social-link.social-xing:hover {
background:#1a7576;
border-color:#1a7576
}
.social-link.social-instagram:hover {
background:#7c4a3a;
border-color:#7c4a3a
}
.no-border .social-link {
border:0;
line-height:30px
}
.no-border .social-link:not(:hover) {
background:transparent
}
.no-backgrond .social-link {
background-color:none;
line-height:30px
}
.no-backgrond .social-link:not(:hover) {
background:transparent
}
.product-single .divider {
margin-left: 1rem;
margin-right: 1rem;
}
.product-single .product-action {
display:inline-block;
position: relative;
opacity: 1;
}
.product-single .product-action .btn-wishlist {
position:relative;
}
.product-single .btn-compare, .product-single .btn-wishlist {
display: inline-block;
padding: 0.5rem 0;
font-weight: 400;
font-size: 14px;
background-color: transparent;
transition: color 0.3s;
text-transform: none;
color: #999 !important;
}
.product-single .btn-compare i, .product-single .btn-wishlist i {
display: inline-block;
margin: 0 0.5rem 0.3rem 0;
vertical-align: middle;
font-size:18px;
line-height: 0;
}
.product-single .product-button{
display:flex;
align-items:center;
}
.product-button button{
height: 45px;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
min-width:150px;
font-weight: 400;
}
.product-single button i{
margin-right:7px;
}
.product-single .product-short{
font-size:14px;
}
.product-single .product-short p{
font-size:14px;
}
.product-single .product-highlight{
width:100%;
position:relative;
margin:10px 0px;
}
.product-highlight ul{
margin:0;
padding:0;
list-style:none;
}
.product-highlight ul li{
display: inline-block;
width: 100%;
position: relative;
padding-left: 25px;
font-size: 14px;
margin-bottom: 5px;
}
.product-highlight ul li:before{
content: "";
font-family: "riode" !important;
speak: never;
font-style: normal;
font-weight: normal;
font-feature-settings: normal;
font-variant: normal;
text-transform: none;
position: absolute;
left: 0;
top: 0;
}
/*=====================================================*/
@media (max-width: 991px){
.product-media img{
height:250px;
}
.product-wvideo .product-media video{
height:270px;
}
.ratings-container{
font-size:9px;
margin-bottom:0px;
}
.product-price{
font-size:13px;
}
.product-price .old-price{
font-size:12px;
}
.product-price .off {
font-size: 12px;
}
.product-highlight ul li{
font-size: 12px;
}
}
/*=====================================================*/
.mproduct-details{
width:100%;
position:relative;
}
.mproduct-details .product-name {
font-size:16px;
font-weight: 700 !important;
white-space: normal;
margin-bottom:10px;
}
.mproduct-details .product-price{
font-size:18px;
}
.mproduct-details .product-price .new-price {
margin-right:7px;
text-decoration: none;
}
.mproduct-details .product-price .old-price {
color: #aaa;
margin-right:7px;
font-size: 16px;
}
.mproduct-details .product-price .off {
color: #388e3c;
font-size: 16px;
}
.mproduct-details .ratings-container{
font-size:12px;
}
.mproduct-details-carousel img{
width:100%;
}
.mproduct-details-carousel .owl-dots{
margin-top: -30px !important;
z-index: 99999;
position: absolute;
width: 100%;
}
.mproduct-footer{
width:100%;
position:fixed;
bottom:0;
padding:15px;
display:flex;
align-items:center;
background:var(--color-white);
border-top: 1px solid var(--color-gray);
z-index: 999;
}
.mproduct-footer button{
width:100%;
display:flex;
align-items:center;
justify-content:center;
}
/*=====================================================*/
/*=========================================================*/
.product-details-tabs .nav-tabs{
border-bottom: 1px solid var(--color-gray-100) !important;
border-top:1px solid var(--color-gray-100) !important;
padding: 0px !important;
}
.product-details-tabs .nav-tabs .nav-link{
color:#333;
}
.product-details-tabs .nav-link{
background:transparent;
font-size: 16px !important;
padding: 15px 15px 10px 15px !important;
}
.product-details-tabs .nav-tabs .nav-link{
background-color:transparent !important;
border:0px !important;
text-transform:uppercase;
}
.product-details-tabs .nav-link.active{
background-color:transparent !important;
color:var(--color-primary) !important;
border:0px !important;
}
.product-details-tabs .tab-pane{
padding-top:10px;
}
/*=========================================================*/
/*==================================================*/
.order-box{
position: relative;
background: #fff;
width: 100%;
display: flex;
border: 1px solid #eee;
border-radius: 5px;
margin-bottom: 20px;
}
.order-box .info {
position: relative;
width: 75%;
border-right: 1px dashed #e3e3e3;
padding: 15px;
}
.order-box .bcode {
position: relative;
width: 25%;
padding: 25px;
text-align: center;
}
.order-box .orderid-box {
background: #f5f5f5;
border: 1px solid #eee;
padding: 10px 10px 10px 10px;
}
/*==================================================*/
/*Form Wizard*/
.bs-wizard {padding: 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 16px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #333; font-size: 14px;margin-top: 15px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 20px; height: 20px; display: block; background: #fbe8aa; top: 17px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 12px; height: 12px; background: #fbbd19; border-radius: 50px; position: absolute; top: 4px; left: 4px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 4px; box-shadow: none; margin: 10px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
/*==================================================*/
.mheader{
width: 100%;
position: relative;
background: #fff;
box-shadow: 0 4px 12px 0 rgba(0,0,0,.05);
justify-content: space-between;
align-items: center;
height: 60px;
padding: 0px 15px;
border-bottom: 1px solid #eee;
}
.mheader-left{
display: flex;
align-items: center;
}
.mheader .mheader-title {
display: flex;
align-items: center;
font-size: 16px;
font-weight: 500;
}
.mheader .mheader-title i {
margin-right: 15px;
font-size: 24px;
}
.sidenav-trigger{
font-size: 18px;
margin-right: 20px;
}
/*==================================================*/
/*==================================================*/
/*==================================================*/
.siderbar-filter{
display:block;
}
.filterbox{
background: #fff;
box-shadow:rgba(33,33,33,.06) 0 4px 24px 5px;
width:100%;
position:relative;
border-radius:10px;
}
.filterbox .filterbox-title{
padding:20px 15px;
font-size:16px;
font-weight:600;
}
.filterbox .filterbox-body{
padding:15px;
}
.filter-modal{
height:300px;
padding:0px 25px 25px !important;
overflow-y: auto;
overflow-x: hidden;
}
.filter-common{
padding:0px 15px 15px !important;
}
.filter-common{
padding:0px 15px 15px !important;
}
.filter-height{
height: 300px;
overflow-x: hidden;
overflow-y: auto;
}
.filter-item i{
top:0;
}
.filter-item a{
color:var(--color-dark);
}
.filter-item-collapse{
height: 200px;
padding: 15px;
overflow-x: hidden;
overflow-y: auto;
}
.filter-item-collapse ul{
margin:0;
padding:0;
list-style:none;
}
.filter-item-collapse ul li{
font-size:13px;
color:#666;
}
.filter-list{
width:100%;
position:relative;
}
.filter-list ul{
margin:0;
padding:0;
list-style:none;
}
.filter-list ul li{
display:flex;
align-items:center;
height:35px;
font-size:13px;
}
.filter-list input[type=checkbox] {
position: relative;
border-radius: 2px;
background: #bebebe;
cursor: pointer;
line-height: 0;
margin: 0 .6em 0 0;
outline: 0;
padding: 0 !important;
vertical-align: text-top;
height:18px;
width: 18px;
-webkit-appearance: none;
opacity: .5;
}
.filter-list input[type=checkbox]:hover {
opacity: 1;
}
.filter-list input[type=checkbox]:checked {
background-color: #000;
opacity: 1;
}
.filter-list input[type=checkbox]:before {
content: '';
position: absolute;
right: 50%;
top: 50%;
width: 4px;
height: 10px;
border: solid #bebebe;
border-width: 0 2px 2px 0;
margin: 0;
transform: rotate(45deg) translate(-50%, -50%);
z-index: 2;
}
/*==================================================*/
footer{
padding: 60px 0px 30px;
width: 100%;
position: relative;
background: var(--color-secondary);
color:#fff;
}
.footer-title{
width:100%;
position: relative;
margin-bottom: 10px;
}
.footer-title h5{
margin-bottom: 0;
font-size: 16px;
}
.footer-list{
width:100%;
position: relative;
margin-bottom: 20px;
}
.footer-list ul{
margin: 0;
padding: 0;
list-style: none;
}
.footer-list ul li{
display: inline-block;
width: 100%;
position: relative;
font-size: 13px;
}
.footer-list ul li a{
line-height:32px;
}
.footer-bottom{
padding: 20px 0px;
width: 100%;
position: relative;
background: var(--color-secondary);
color:#fff;
text-align: center;
display: flex;
flex-direction: column;
}
.payment img{
width: 500px;
}
.footer-right{
justify-content: center;
display: flex;
}
.footer-social{
position: relative;
}
.footer-social ul{
margin: 0;
padding: 0;
list-style: none;
}
.footer-social ul li{
display: inline-block;
margin-left: 10px;
}
.footer-social ul li a{
border: 1px solid #fff;
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
text-align: center;
}
.footer-social ul li a i{
font-size: 13px;
margin-top: 2px;
}
.subscribe-form {
position: relative;
width:100%;
}
.subscribe-form input {
height: 47px;
background: #f5f5f5;
border: 0;
color: #b1b1b1;
padding: 10px 120px 10px 20px;
display: inline-block;
width: 100%;
font-size: 14px;
border: none;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
border-radius: 5px;
outline: none;
}
.subscribe-button input.button {
position: absolute;
top: 0;
right: 0;
text-transform: capitalize;
font-weight: 400;
font-size: 14px;
color: #fff;
background: var(--color-primary);
border: 0;
box-shadow: none;
padding: 0 20px;
line-height: 47px;
height: 47px;
border-radius: 0 5px 5px 0;
-webkit-transition: all 300ms linear;
-moz-transition: all 300ms linear;
-ms-transition: all 300ms linear;
-o-transition: all 300ms linear;
transition: all 300ms linear;
width: 90px;
outline: none;
}
@media (max-width: 991px){
.footer-bottom{
padding: 20px;
}
.payment img {
width: 100%;
}
}
/*=========================================================*/
.cart-modal .cartbox{
padding:25px;
width: 100%;
height: 100vh;
position:fixed;
overflow-x: hidden;
overflow-y: auto;
top:0;
left:0;
}
.cartbox .cartbox-header{
display: flex;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid #eee;
padding-bottom:10px;
}
.cartbox-header .cartbox-title {
margin-bottom: 0;
font-size: 16px;
font-weight: 700;
text-transform: uppercase;
}
.cartbox-close{
font-size:18px;
}
.cartbox .cartbox-scroll{
max-height: 360px;
overflow-x: hidden;
width:100%;
padding-bottom:50px;
}
.cartbox .product-cart{
margin-top:15px;
display: flex;
align-items: center;
position: relative;
}
.cartbox .product-cart .product-media{
position: static;
width: 60px;
margin-right:10px !important;
height: 60px;
}
.cartbox .product-cart .product-media a{
display: block;
height: 100%;
padding: 0;
}
.cartbox .product-cart .product-media img{
height:100%;
width: 100%;
object-fit: cover;
border-radius: 5px;
}
.cartbox .product-detail{
flex: 1;
margin: 0 2rem 0rem 0;
}
.cartbox .product-detail .product-name{
white-space: normal;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
font-size: 12px;
height: auto;
}
.cartbox .product-detail .price-box{
display: flex;
align-items: center;
font-size: 14px;
}
.price-box .product-quantity{
align-items: center;
display: flex;
margin-right: 0.5rem;
font-weight: 400;
}
.price-box .product-quantity::after{
margin-left: 0.5rem;
content: "X";
text-transform: none;
line-height: 0;
font-size: 13px;
}
.price-box .product-price{
margin:0;
font-size:13px;
}
.cartbox .product-remove{
position: absolute;
right: 0;
top: 4px;
font-size: 8px;
border-radius: 50%;
width: 16px;
height: 16px;
display: flex;
align-items: center;
justify-content: center;
}
.cartbox .product-remove i{
font-size:10px;
}
.cartbox .cartbox-footer{
position: fixed;
bottom:0;
left:0;
width:100%;
padding:15px 25px 25px 25px;
background:var(--color-white);
}
.cartbox .cartbox-total{
display:flex;
align-items:center;
justify-content:space-between;
border-top: 1px solid #eee;
border-bottom: 1px solid #eee;
padding:10px 0px;
margin-bottom:20px;
font-weight:600;
}
.cartbox .cartbox-action{
text-align:center;
width:100%;
position:relative;
}
.cartbox-action-btn{
display: inline-block;
border-bottom: 2px solid var(--color-primary);
margin-bottom: 20px;
}
.cartbox .btn{
display: flex;
justify-content: center;
border-radius: 5px;
padding: 0.9em 2em;
font-weight: 500;
}
/*==================================================*/

/*==================================================*/
.appBottomMenu{
position: fixed;
z-index: 999;
bottom: 0;
left: 0;
right: 0;
background: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
border-top: 1px solid #E1E1E1;
min-height: 56px;
}
.appBottomMenu .item {
font-size: 12px;
letter-spacing: 0;
text-align: center;
width: 100%;
height: 56px;
line-height: 1.2em;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}
.appBottomMenu .item .col {
width: 100%;
padding: 0 4px;
text-align: center;
}
.appBottomMenu .item i {
display: inline-flex;
margin: 1px auto 3px auto;
font-size: 20px;
line-height: 1em;
color: #141515;
transition: none;
display: block;
margin-top: 1px;
margin-bottom: 3px;
}
.appBottomMenu .item.active:before {
background: transparent;
}
.appBottomMenu .item.active{
color: var(--color-primary) !important;
}
.appBottomMenu .item.active i{
color: var(--color-primary) !important;
font-weight: 500;
}
/*==================================================*/
.form-group{
position: relative;
width: 100%;
}
.form-group label{
margin-bottom:5px;
}
.form-group .form-control {
border: 1px solid #eee;
height: 50px;
padding: 0px 15px;
border-radius: 5px;
}
.form-control::-moz-placeholder {
color:#6c757d !important;
opacity:1
}
.form-control::placeholder {
color:#6c757d !important;
opacity:1
}
.country-input .form-control{
padding-left: 80px;
}
.country-input .country-code{
position: absolute;
top: 0;
height: 50px;
display: flex;
align-items: center;
min-width: 65px;
justify-content: center;
border-right: 1px solid #eee;
display: flex;
justify-content: space-around;
padding: 0px 5px;
}
.country-code::after{
content: "";
font-family: "riode" !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
font-size: 10px;
}
.form-group-dark{
position: relative;
width: 100%;
}
.form-group-dark label{
margin-bottom:5px;
}
.form-group-dark .form-control {
height: 50px;
padding: 0px 15px;
border-radius: 5px;
color:#000;
border:1px solid var(--color-gray-200);
background:var(--color-white);
}
.form-group-dark textarea{
height:150px !important;
}
.form-group-white{
position: relative;
width: 100%;
}
.form-group-white label{
color:#333;
margin-bottom:5px;
font-size:13px;
font-weight:600;
}
.form-group-white .form-control {
border: 1px solid #eee;
height: 45px;
padding: 0px 15px;
border-radius: 5px;
font-size:13px;
}
@media (max-width: 991px){
.form-group-white label{
font-size:12px;	
}	
}
/*==================================================*/
.country-list{
position: absolute;
top: 0;
left: 0;
background: #fff;
width: 100%;
height: 100%;
z-index: 999999;
overflow:auto;
border-radius: 5px;
}
.country-list .country-list-header{
padding:15px 20px;
border-bottom:1px solid #eee;
width:100%;
position:relative;
display:flex;
align-items:center;
justify-content:space-between;
}
.country-list .country-list-header h6{
margin-bottom:0px;
}
.country-list ul{
margin:0;
padding:0;
list-style:none;
}
.country-list ul li{
display:flex;
align-items:center;
cursor:pointer;
width:100%;
height:40px;
border-bottom: 1px solid #eee;
padding:0px 25px;
}
.country-list ul li span{
margin-left:auto;
}
/*==================================================*/
.noimg{
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align:center;
padding:20px;
}
.noimg img{
width:300px;
}
@media (max-width: 991px){
.noimg img{
width:100%;
}	
}
/*==================================================*/
.reviewModal-content{
padding: 25px;
width: 100%;
height: 100vh;
position: fixed;
overflow-x: hidden;
overflow-y: auto;
top: 0;
left: 0;
}
.reviewModal-content .reviewModal-header{
display: flex;
justify-content: space-between;
padding-bottom:10px;
}
.reviewModal-header .reviewModal-title {
margin-bottom: 0;
font-size: 16px;
font-weight: 700;
}
.reviewModal-close{
font-size:18px;
background:transparent;
border:0px;
color:#000;
height: 0px !important;
}
.reviewModal-body{
width:100%;
position:relative;
}
.review-form{
width:100%;
position:relative;
}
.review-form label{
width:100%;
position:relative;
margin-bottom:5px;
display: inline-block;
}
.review-form textarea{
width:100%;
border: 1px solid var(--color-gray-dark);
background: var(--color-white);
height:150px;
border-radius:5px;
}
/*=========================================================*/
.quickViewModal .modal-dialog{
max-width:900px;
}
.quickViewModal .modal-content{
background:var(--color-white);
}
.quickViewModal .modal-body{
padding:15px 0px;
}
.addressModal .modal-dialog{
max-width:450px;
}
.addressModal .modal-body{
padding:25px;
}
.addressModal-header{
border-bottom:1px solid #eee;
}
.addressModal .modal-footer{
display:flex;
flex-wrap: unset;
}
.quickViewModal .product-button .pbqty{
width:100px;
}
.quickViewModal .product-button button{
min-width: 155px;
}
.quickViewModal .mproduct-details-carousel img{
border-radius:10px;
}
.quickViewModal .pop-close{
box-sizing: content-box;
width: 1em;
height: 1em;
padding: .25em .25em;
color: #000;
background: transparent;
border: 0;
border-radius: .25rem;
opacity: .5;
position: absolute;
top:15px;
right: 20px;
cursor: pointer;
z-index: 9999;
}
@media (max-width: 991px){
.quickViewModal{
background:var(--color-white);
}
.quickViewModal .modal-dialog {
margin: 0;
height: 100%;
max-width: 100%;
}
.quickViewModal .modal-content{
height: 100%;
}
.quickViewModal .product-button button{
min-width: 100%;
margin-right: 0 !important;
margin-bottom: 10px;
}
.quickViewModal .product-button .pbqty{
width:100%;
margin-bottom:10px;
margin-right:0px;
}
.quickViewModal .pop-close{
top: 5px;
right: 5px;
}
.quickViewModal .product-button{
flex-direction: column;
}
.addressModal{
background:#fff;
}
.addressModal .modal-dialog{
margin: 0;
height: 100%;
max-width: 100%;
}
.addressModal .modal-body{
padding:20px;
}
.addressModal .modal-content{
border-radius: 0px;
height: 100%;
border:0px;
}
}
.maddress-footer{
position:fixed;
bottom:0;
left:0;
padding:15px;
width:100%;
border-top:1px solid var(--color-white);
}
/*=========================================================*/
.mcatbox{
display:flex;
align-items:center;
height:80px;
padding:0px 15px;
border-bottom:1px solid var(--color-gray);
}
.mcatbox .mcatbox-inner{
display:flex;
align-items:center;
width:100%;
}
.mcatbox .mcatbox-arrow{
margin-left: auto;
height: 80px;
display: flex;
align-items: center;
width: 35px;
text-align: right;
justify-content: end;
}
.mcatbox .mcatbox-media{
width:60px;
height:60px;
background:var(--color-black-light);
border-radius:5px;
}
.mcatbox .mcatbox-media img{
width:60px;
height:60px;
object-fit:cover;
border-radius:5px;
}
.mcatbox .mcatbox-content{
flex:1;
padding-left:15px;
padding-right:35px;
}
.mcatbox h6{
font-size:14px;
margin-bottom:0px;
}
.mcatbox p{
margin-bottom:0px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
font-size:12px;
}
.mcatbox-list{
width:100%;
position:relative;
}
.mcatbox-list ul{
margin:0;
padding:0;
list-style:none;
}
.mcatbox-list ul li{
display:flex;
align-items:center;
justify-content:space-between;
height:40px;
padding:0px 15px;
border-bottom:1px solid var(--color-gray-200);
}
.mcatbox-list ul li:last-child{
border-bottom:0px;
}
/*==================================================*/
/*==================================================*/
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 320px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.menu-modal.left .modal-dialog{
position: fixed;
margin: auto;
width: 270px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.reviewModal.right .modal-dialog{
position: fixed;
margin: auto;
width: 500px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.cart-modal.right .modal-dialog{
    position: fixed;
    margin: auto;
    width: 400px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    }
.modal.left .modal-content,
.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}
.modal.left .modal-body,
.modal.right .modal-body {
padding: 15px 15px 80px;
}
.cart-modal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.menu-modal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.reviewModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
/*Left*/
.modal.left.fade .modal-dialog{
left: -320px;
-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
-o-transition: opacity 0.3s linear, left 0.3s ease-out;
transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.show .modal-dialog{
left: 0;
}
/*Right*/
.modal.right.fade .modal-dialog {
right: -320px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
right: 0;
}
.cart-modal .modal-content{
background: var(--color-white);
border-radius: 0px;
border: 0px;
}
.menu-modal .modal-content{
background: var(--color-white);
border-radius: 0px;
}
.reviewModal .modal-content{
background: var(--color-white);
border-radius: 0px;
}
/*=========================================================*/
.dvariation{
width:100%;
display:flex;
margin-bottom:15px;
margin-top:15px;
flex-direction: column;
}
.dvariation label{
margin-bottom:10px;
font-weight:600;
}
.dvariation-list{
width:100%;
position:relative;
display:flex;
align-items:center;
}
.dvariation-list a{
margin-right:5px;
border-radius:3px;
}
.dvariation-list .size{
padding:3px 10px;
border: 1px solid var(--tx-color-03);
background:var(--color-white);
font-size:13px;
}
.dvariation-list .size:hover{
border: 1px solid var(--color-secondary);	
background:var(--color-secondary-light);
}
.dvariation-list .size.active{
border: 1px solid var(--color-secondary);	
background:var(--color-secondary-light);
}
.dvariation-list .color{
width:20px;
height:20px;
border: 1px solid var(--tx-color-03);
background:var(--color-white);
border-radius: 2px;
}
.dvariation-list .color.active{
border: 1px solid var(--color-primary);	
background:var(--color-primary);
}
.dvariation-list .swatch{
width: 50px;
height: 50px;
}
.dvariation-list .swatch img{
width: 50px;
height: 50px;
object-fit:cover;
}
.dvariation-list .swatch.active img{
border: 1px solid var(--color-primary);	
}
/*=========================================================*/
.mvariation{
width:100%;
display:flex;
flex-direction: column;
margin-bottom:10px;
}
.mvariation label{
margin-bottom:10px;
}
.mvariation-list{
width:100%;
position:relative;
display:flex;
align-items:center;
}
.mvariation-list a{
margin-right:10px;
}
.mvariation-list .size{
padding: 5px 10px;
border: 1px solid var(--color-black-light);
background:var(--color-black-light-02);
border-radius: 5px;
font-size: 12px;
}
.mvariation-list .size.active{
border: 1px solid var(--color-primary);	
}
.mvariation-list .color{
width:20px;
height:20px;
border: 1px solid var(--color-black-light);
background:var(--color-black-light-02);
border-radius:2px;
}
.mvariation-list .color.active{
border: 1px solid var(--color-primary);	
}
.mvariation-list .swatch{
width: 40px;
height: 40px;
}
.mvariation-list .swatch img{
width: 40px;
height: 40px;
object-fit:cover;
}
.mvariation-list .swatch.active img{
border: 1px solid var(--color-primary);	
}
@media (max-width: 991px){
.mvariation{
margin-bottom:15px;
}
.mvariation label{
margin-bottom:6px;
}   
}
/*=========================================================*/
/*===============================================*/
.whatsapp{
position:fixed;
bottom:15px;
right:15px;
z-index:9;
}
.whatsapp img{
width:52px;
}
.whatsapp-mobile {
position: fixed;
bottom: 62px;
right: 15px;
z-index: 9;
}
.whatsapp-mobile img{
width:52px;
}
/*=================================================*/
.vi-box-section{
display: flex;
flex-wrap: wrap;
}
.vi-box{
position: relative;
width: 20.5%;
margin-bottom: 10px;
text-align: center;
margin-right: 10px;
}
.vi-box .vi-box-inner {
background: #fff;
border: 1px solid #ddd;
border-radius: 5px;
height: 70px;
margin-bottom: 3px;
overflow: hidden;
position: relative;
width: 100%;
}
.vi-box img{
border-radius: 5px;
height: 70px;
object-fit: cover;
width: 100%;
}
.remove{
position: absolute;
top: 5px;
right: 5px;
color: #fff;
background: #ef4b46;
border-radius: 50%;
width: 24px;
height: 24px;
display: flex;
align-items: center;
justify-content: center;
}
.remove i{
margin-top: 3px;
}
/*=================================================*/
.pss-slider{
display: flex;
flex-wrap: wrap;
}
.pss-slider .gallery-thumbs {
order: -1;
max-width: 109px;
position: relative;
overflow: hidden;
flex-basis: 100%;
margin-right: 10px;
}
.gallery-thumbs .swiper-wrapper {
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
transition: top 0.3s ease-out;
}
.gallery-thumbs .swiper-slide{
position: relative;
cursor: pointer;
overflow: hidden;
}
.gallery-thumbs .swiper-slide img{
width:100%;
height:100%;
object-fit:cover;
}
.gallery-thumbs .swiper-slide {
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
cursor: pointer;
}
.gallery-thumbs .swiper-slide-active:before {
content: "";
transition: border-color 0.3s;
border: 2px solid var(--color-primary);
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
}
.gallery-thumbs .swiper-slide:hover {
border: 2px solid var(--color-primary);
}
.pss-slider .gallery-top {
max-width: calc(100% - 119px);
flex-basis: 100%;
}
.gallery-top .swiper-slide {
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
cursor: pointer;
}
.pss-slider .gallery-top .swiper-slide img{
width:100%;
}
/*=========================================================*/
.panel {
width: 100%;
position: relative;
background:var(--color-white);
border: 1px solid var(--color-gray);
border-radius: 5px;
}
.panel .panel-header {
font-size: 18px;
border-bottom: 1px solid var(--color-gray);
font-weight: 600;
padding-bottom: 15px;
display: flex;
align-items: center;
}
.cartsec {
width: 100%;
position: relative;
border:1px solid var(--color-gray);
padding: 15px;
border-radius: 5px;
margin-bottom: 15px;
}
.cartsec .cartsec-media{
width:100%;
position:relative;
}
.cartsec .cartsec-media img{
width:100%;
border-radius:5px;
height:150px;
object-fit:cover;
}
.cartsec .cartsec-name {
font-size: 16px;
font-weight:500 !important;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.cartsec .cartsec-price {
display: flex;
align-items: center;
font-size: 16px;
margin-top: 10px;
}
.cartsec-price .price-new{
font-weight: 600;
}
.cartsec-price .price-old {
font-weight: 500;
text-decoration: line-through;
color:#aaa;
}
.cartsec-footer {
display: flex;
align-items: center;
justify-content: space-between;
margin-top:30px;
}
.qty-changer {
display: flex;
align-items: center;
}
.qty-changer button {
background:var(--color-black);
border:1px solid var(--color-primary);
border-radius: 3px;
width: 25px;
height: 25px;
display: flex;
align-items: center;
justify-content: center;
color:#333;
}
.qty-changer .psbmiuns {
font-size: 9px !important;
height: 7px;
}
.qty-changer .psbplus {
font-size: 9px !important;
height: 7px;
}
.qty-changer span {
width: 40px;
height: 25px;
display: flex;
font-size: 16px;
font-weight: 600;
align-items: center;
justify-content: center;
} 
.cartsec-buttongroup{
position:relative;
}
.cartsec-buttongroup a {
background:var(--color-black);
font-size: 12px;
padding: 5px 10px;
border-radius: 3px;
margin-left: 10px;
border:1px solid var(--color-primary);
}
.cartsec-buttongroup a i{
font-size:11px; 
}
.applycoup-desktop{
display:flex;
align-items:center;
justify-content:space-between;
}
.applycoup-desktop h6{
font-size:16px;
font-weight:600;
}
.applycoup-mobile{
width:100%;
position:relative;
border-bottom: 2px solid var(--color-gray);
}
.applycoup-mobile .applycoup-mobile-inner{
display:flex;
align-items:center;
justify-content:space-between;
height:50px;
padding:0px 15px;
}
.applycoup-mobile .applycoup-mobile-text{
display:flex;
align-items:center;
}
.applycoup-desktop .applycoup-mobile-text{
display:flex;
align-items:center;	
}
.applycoup-mobile-text img{
margin-right:10px;
width:24px;
}
.apply-coupons-box{
display:flex;
align-items:center;
}
.apply-coupons-box input{
width:100%;
height:50px;
border:1px solid #ddd;
border-radius:5px;
padding:15px;
font-size:13px;
}
.apply-coupons-box .apply-buttton{
background:var(--color-primary);
border:1px solid var(--color-primary);
flex:1;
margin-left:10px;
height:50px;
padding:0px 25px;
border-radius:5px;
color:#fff;
cursor: pointer;
font-size:15px;
}
.pcb-list {
width: 100%;
position: relative;
}
.pcb-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.pcb-list ul li {
display: flex;
align-items: center;
height: 35px;
}
.ml-auto, .mx-auto {
margin-left: auto !important;
}
.pcb-list-second {
width: 100%;
position: relative;
}
.pcb-list-second ul {
margin: 0;
padding: 0;
list-style: none;
}
.pcb-list-second ul li {
display: flex;
align-items: center;
height: 40px;
font-size: 14px;
font-weight: 600;
}
.cardlist {
width: 100%;
position: relative;
display: flex;
justify-content: center;
}
.cardlist i{
font-size: 32px;
margin: 3px;
}
/*============================================*/
@media (max-width: 991px){
.applycoup-mobile h6{
font-size:16px;
font-weight:600 !important
}
.cartsec{
margin:0;
border-bottom:2px solid var(--color-gray);
border-radius:0px;
background:var(--color-black);
}
.cartsec .cartsec-name {
font-size: 13px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 20px;
font-weight:600 !important;
}
.cartsec .cartsec-media img{
width:100%;
border-radius:5px;
height:85px;
object-fit:cover;
}
.cartsec .cartsec-price {
display: flex;
align-items: center;
font-size: 14px;
margin-top: 10px;
}
.cartsec-buttongroup a{
padding: 3px 7px;
}
.cartsec-buttongroup a i{
margin-right:0px;
font-size: 9px;
}
.qty-changer button{
width:20px;
height:20px;
}
.qty-changer .psbmiuns {
font-size: 7px !important;
height: 5px;
}
.qty-changer .psbplus {
font-size: 7px !important;
height: 5px;
}
.qty-changer span {
width: 30px;
height:20px;
font-size:12px;
}
.cartsec-footer {
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 10px;
}
.checkoutlist{
padding:15px;
width:100%;
position:relative;
background:var(--color-black);
}
.panel .panel-header {
font-size: 14px;
border-bottom: 0px solid var(--color-black-light-02);
font-weight: 600;
padding-bottom: 0px;
display: flex;
align-items: center;
}
.pcb-list ul li {
display: flex;
align-items: center;
height: 30px;
font-size: 13px;
}
.checkoutlist hr{
margin:0.5rem 0px;
}
.footer-checkout {
position: fixed;
bottom: 0;
width: 100%;
padding: 15px;
background: var(--color-white);
border-top: 1px solid var(--color-gray);
}
}
/*============================================*/
/*============================================*/
/*=========================================================*/
.step-by {
display: flex;
justify-content: center;
flex-wrap: wrap;
padding: 10px;
}
.step-by .title-step {
margin:0 3.8rem 0 0rem;
margin-left: 1rem;
font-size: 14px;
text-transform: uppercase;
font-weight: 500 !important;
color: #333;
padding: .4rem 0;
position:relative;
}
.step-by .title-step:first-child {
margin-left: 0;
}
.step-by .title-step.active a{
color:var(--color-primary) !important;
}
.title-simple {
display: block;
}
.step-by .title-step:not(:last-child)::after {
content: '\f054';
display: inline-block;
position: absolute;
right: -3.5rem;
top: 47%;
line-height: 0;
vertical-align: middle;
font-family: 'Font Awesome 5 Free';
font-weight: 700;
font-size: 0.8rem;
background-color: #fff;
color: #ccc;
}
.secure-img{
width:125px;
}
/*==============================================*/
/*==============================================*/
.msprofile{
width:100%;
position: relative;
padding:10px 15px;
border-bottom: 1px solid var(--color-gray);
}
.msprofile .msprofile-media{
width:60px;
height:60px;
overflow: hidden;
background: var(--color-gray);
border-radius: 50px;
}
.msprofile .msprofile-media img{
width:60px;
height:60px;
object-fit: cover;
}
.msprofile .msprofile-content{
flex: 1;
padding-left: 15px;
}
.msprofile-arrow{
margin-left: auto;
}
.sidemenu{
padding: 15px 0px;
width:100%;
position: relative;
}
.mslist{
margin: 0;
padding: 0;
list-style: none;
}
.mslist ul{
margin: 0;
padding: 0;
list-style: none;
}
.mslist ul li{
display: flex;
align-items: center;
height: 35px;
padding: 0px 15px;
font-size: 13px;
}
/*==============================================*/
.comentlist{
width:100%;
position:relative;
}
.comentlist ul{
margin:0;
padding:0;
list-style:none;
}
.comentlist ul li{
display:inline-block;
width:100%;
position:relative;
border-bottom:1px solid var(--color-black-light-02);
padding-bottom:15px;
margin-bottom:15px;
}
.comment {
position: relative;
display: flex;
align-items: flex-start;
}
.comment .comment-media {
max-width: 50px;
flex: 0 0 50px;
height: 50px;
overflow: hidden;
border:1px solid var(--color-black-light-02);
}
.comment .comment-media img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}
.comment .comment-body {
position: relative;
flex: 1 1 auto;
padding:0rem 0 0 1rem;
}
.comment .comment-user{
margin-bottom:10px;
}
.comment .comment-body .comment-meta {
display: block;
margin-bottom: 0.5rem;
font-size:14px;
color: #999;
line-height: 1;
}
.comment .comment-body .comment-name{
margin-right:5px;
}
.comment .comment-body .comment-date{
margin-left:5px;
}
.comment .comment-body .comment-content{
font-size:13px;
line-height:24px;
}
.comment .comment-body .comment-content p{
margin-bottom:0px;
}
.avg-rating-container {
display: flex;
align-items: center;
margin-bottom: 20px;
}
.avg-rating-container mark {
font-size: 62px;
font-weight: 700;
line-height: 1;
color: var(--color-primary);
background: none;
margin-right: 15px;
padding:0px;
}
.ratings-item {
display: flex;
align-items: center;
margin-bottom:5px;
}
.ratings-item .rating-percent {
background: #eee;
height: 0.5rem;
width: 200px;
max-width: 100%;
border-radius: 1rem;
}
.rating-percent span {
display: block;
height: 100%;
border-radius: inherit;
background: #999;
}
.ratings-item .ratings-container {
margin-right: 15px;
}
.ratings-item .rating-value {
margin-left: 10px;
}
/*==============================================*/
@media (max-width: 991px){
.comentlist{
margin-top:30px;
}
.comentlist ul li{
border-bottom:0px;
padding-bottom: 10px;
margin-bottom: 10px;
}
.avg-rating-container mark {
font-size: 42px;
font-weight: 700;
line-height: 1;
color: var(--color-primary);
background: none;
margin-right: 15px;
padding: 0px;
}
.ratings-item .rating-percent {
background: #eee;
height: 0.5rem;
width: 100%;
max-width: 100%;
border-radius: 1rem;
flex: 1;
}
.ratings-item .rating-value {
margin-left: 10px;
width: 30px;
}
.comment .comment-body {
position: relative;
flex: 1 1 auto;
padding:0;
}
}
/*==============================================*/
.gallery-page__single {
position: relative;
display: block;
margin-bottom: 0;
}
.gallery-page__img {
position: relative;
display: block;
overflow: hidden;
z-index: 1;
}
.gallery-page__img img {
width: 100%;
-webkit-transform: scale(1);
transform: scale(1);
-webkit-transition: -webkit-transform 500ms ease;
transition: -webkit-transform 500ms ease;
transition: transform 500ms ease;
transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.gallery-page__icon {
position: absolute;
bottom: 15px;
right: 15px;
font-size:24px;
}
/*=========================================================*/
.mlist{
width:100%;
position:relative;
}
.mlist ul{
margin:0;
padding:0;
list-style:none;
}
.mlist ul li{
display:flex;
align-items:center;
justify-content:space-between;
height:40px;
padding:0px 15px;
border-bottom:1px solid var(--color-gray);
}
.mlist ul li:last-child{
border-bottom:0px;
}
/*=======================================*/
.addresscard{
border-radius:5px;
padding:15px 15px 15px 40px;
width:100%;
position:relative;
margin-top:15px;
background:var(--color-black-light)
}
.addresscard h6{
display:flex;
margin-bottom:5px;
}
.save-address-check {
width: 16px;
height: 16px;
border-radius: 50%;
border: 2px solid #ddd;
background: #f5f5f5;
position: absolute;
left: 15px;
top: 15px;
}
.address-full{
font-size:12px;
}
.save-address-checkout.active .save-address-check{
background:var(--color-primary);
}
.addresscard-type{
background: #f5f5f5;
padding: 2px 10px;
border-radius: 10px;
text-transform: uppercase;
position: absolute;
top: 10px;
right: 10px;
font-size: 11px;
}
@media (max-width:768px) {
.addresscard {
border: 0px solid #ddd;
border-radius: 0;
padding: 15px 15px 15px 40px;
width: 100%;
position: relative;
background:var(--color-black);
margin-top:0px;
border-bottom:2px solid var(--color-gray);
}
.addresscard h6{
display:flex;
margin-bottom:0px;
font-size:12px;
}
.addresscard-type{
background:var(--color-gray);
}
.addressbutton{
position:fixed;
bottom:0;
left:0;
width:100%;
border-top:1px solid var(--color-gray);
padding:15px;
}
}
/*==================================================*/
.address-checkout{
background:var(--color-black-light);
width:100%;
position:relative;
padding:15px;
border-radius:5px;
}
.change-address {
position: absolute !important;
right: 15px;
top: 14px;
font-size: 12px;
color:var(--color-primary) !important;
z-index: 9;
text-transform:uppercase;
}
.checkout-accord{
background:var(--color-white) !important;
width:100%;
position:relative;
border-radius:5px;
border:1px solid var(--color-gray) !important;
margin-bottom: 15px;
}
.checkout-accord .checkout-accord-header{
width:100%;
position:relative;
padding:15px;
display:flex;
align-items:center;
justify-content:space-between;
}
.checkout-accord .checkout-accord-body{
width:100%;
position:relative;
}
.checkout-accord .accordion-button{
background:var(--color-white) !important;
border-bottom:0px !important;
box-shadow:none !important;
}
.checkout-accord .accordion-body{
padding:0px;
}
@media (max-width:968px) {
.checkout-accord{
margin-bottom:0px;
border-bottom:2px solid var(--color-gray) !important;
border-radius:0px;
background:var(--color-white) !important;
}
.checkout-accord .accordion-button{
background:var(--color-white) !important;
}
.accordion-header h6{
font-size:12px;
}
.address-checkout{
background:var(--color-white) !important;
}
.address-checkout h6{
font-size:12px;
}
}
/*=======================================*/
.payment-option-list{
width: 100%;
position: relative;
}
.payment-option-list ul{
margin: 0;
padding: 0;
list-style: none;
}
.payment-option-list ul li{
display: flex;
align-items: center;
height: 55px;
border-bottom: 1px solid #eee;
font-size: 13px;
font-weight: 500;
padding: 0px;
padding:0px 20px;
}
.payment-option-list ul li img{
width:28px;
height:28px;
margin-right: 10px;
}
.payment-option-list ul li:last-child{
border-bottom: 0px solid #eee;
}
/*=================================================*/
/*==============================================*/
/*=====================ANAND=======================*/
/*==============================================*/
.thankusection{
width:100%;
position:absolute;
display:flex;
align-items:center;
height:100%;
left:0;
top:0;
}
.thankusection .thankusection-left{
width:65%;
position:relative;
height:100%;
padding:50px 100px;
}
.thankusection .thankusection-right{
width:35%;
position:relative;
height:100%;
background:var(--color-black-light);
padding:50px 30px;
}
.tsleft-header{
width:100%;
position:relative;
display:flex;
align-items:center;
}
.tsleft-header-icon{
width:65px;
height:65px;
}
.tsleft-header-icon img{
width:65px;
height:65px;
border-radius:50%;
}
.tsleft-header-text{
flex:1;
padding-left:15px;
}
.tsleft-box{
width:100%;
position:relative;
border-radius:5px;
background:var(--color-black-light);
}
.tsleft-box .tsleft-box-header{
padding:15px;
border-bottom:1px solid var(--color-black-light-02);
}
.tsleft-box .tsleft-box-body{
padding:15px;
}
.thankusection-footer{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}
@media (max-width: 991px){
.thankusection{
flex-direction: column;
padding: 15px;
}
.thankusection .thankusection-left{
width:100%;
position:relative;
height:auto;
padding: 0;
}
.thankusection .thankusection-right{
width:100%;
position:relative;
height:auto;
padding: 0;
background:var(--color-black);
margin-top: 0px;
}
.thankusection .cartsec{
padding: 10px 0px;
border-bottom: 1px solid var(--color-black-light-02);
}
.thankusection-footer{
flex-direction: column;
}
.tsleft-header{
width:100%;
position:relative;
display:flex;
align-items:center;
flex-direction: column;
justify-content: center;
text-align: center;
}
.tsleft-header-text{
flex:1;
padding-left:0px;
margin-top: 20px;
}
}
/*==============================================*/
/*=========================================================*/
.dheader-search{
position:relative;
}
.dheader-search-form{
position:absolute;
right:0;
top:46px;
min-width:25rem;
padding:15px;
height:auto;
border-radius:5px;
background-color:var(--color-gray);
box-shadow:0 2px 35px rgba(0,0,0,0.1);
visibility:hidden;
opacity:0;
transform:translate3d(0,-10px,0);
z-index:100;
transition:transform 0.4s 0.2s,visibility 0.4s 0.2s,opacity 0.4s 0.2s;
}
.dheader-search-form input{
width:100%;
height:50px;
border-radius:5px;
padding:15px;
border:1px solid var(--color-gray);
background:var(--color-white);
color:var(--color-black);
}
.dsf-inner{
width:100%;
position:relative;
}
.dsf-inner i{
position:absolute;
top:15px;
right:15px;
font-size:16px;
}
.dheader-search-form ul{
margin:0;
padding:0;
list-style:none;
margin-top:5px;
}
.dheader-search-form ul li{
display:inline-block;
width:100%;
line-height:32px;
cursor:pointer;
}
.dheader-search:hover .dheader-search-form {
transform:translate3d(0,0,0);
visibility:visible;
transition:transform 0.4s;
opacity:1
}
/*==============================================*/
.cartbox-recom{
position: fixed;
background: var(--color-gray-100);
width: 250px;
height: 100%;
right:400px;
padding:25px 20px;
overflow-x: hidden;
overflow-y: auto;
}
.cartbox-recom .cartbox-recom-inner{
width:100%;
position:relative;
}
.cartbox-recom .product{
padding:25px 15px 0px;
}
.cartbox-recom .product-name{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: normal;
font-size:13px;
line-height:24px;
}
.cartbox-recom .product-details{
padding-bottom:10px;
}
/*==============================================*/
.feedbackModal .modal-dialog{
max-width:600px;
}
.feedbackModal .modal-body{
padding:25px;
}
.feedbackModal-header{
border-bottom:1px solid #eee;
}
.feedbackModalbox-form{
width:100%;
position:relative;
color:#333;
}
.feedbackModalbox-form .feedback-from-group{
width:100%;
position:relative;
}
.feedback-from-group label{
width:100%;
position:relative;
margin-bottom:5px;
}
.feedgroup{
display:flex;
align-items:center;
}
.feedgroup .feedgroup-inner{
display:flex;
align-items:center;
}
.feedback-from-group textarea{
width:100%;
border:1px solid #eee;
height:100px;
border-radius:5px;
padding:15px;
}
@media (max-width: 991px){
.feedbackModal{
background:#fff;
}
.feedbackModal .modal-dialog{
margin: 0;
height: 100%;
max-width: 100%;
}
.feedbackModal .modal-body{
padding:20px;
}
.feedbackModal .modal-content{
border-radius: 0px;
height: 100%;
border:0px;
}
}
/*==============================================*/
.feedback-desktop {
background: var(--color-gray);
padding: 25px;
border-radius: 5px;
}
.feedback-group {
width: 100%;
position: relative;
}
.feedback-group label {
margin-bottom: 5px;
width: 100%;
position: relative;
}
.feedback-group input[type="text"] {
width: 100%;
background: var(--color-white);
height: 50px;
border: 1px solid var(--color-white);
border-radius: 5px;
padding: 15px;
color: var(--color-black-light-02);
}
.feedback-group input[type="number"] {
width: 100%;
background: var(--color-white);
height: 50px;
border: 1px solid var(--color-white);
border-radius: 5px;
padding: 15px;
color: var(--color-black-light-02);
}
.feedback-group input[type="email"] {
width: 100%;
background: var(--color-white);
height: 50px;
border: 1px solid var(--color-white);
border-radius: 5px;
padding: 15px;
color: var(--color-black-light-02);
}
.feedback-group textarea {
width: 100%;
background: var(--color-white);
height: 100px;
border: 1px solid var(--color-white);
border-radius: 5px;
padding: 15px;
color: var(--color-black-light-02);
}
.feedback-group .feedbackd-gr {
display: flex;
align-items: center;
}
.feedback-group .feedbackd-gr-inner {
display: flex;
align-items: center;
}
/*==============================================*/
.cancleform{
width:100%;
position:relative;
}
.cancleform ul{
margin:0;
padding:0;
list-style:none;
}
.cancleform ul li{
display:flex;
align-items:center;
height:40px;
color:var(--color-black);
}
.cancleform ul li span{
margin-left:10px;
}
/*==============================================*/
.btn-primary-line{
background:transparent;
color:var(--color-secondary) !important;
border-bottom:1px solid var(--color-secondary) !important;
border-radius:0px !important;
padding:0px !important;
}
/*==============================================*/
.aobox{
width: 100%;
height: 150px;
background: var(--color-gray);
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align:center;
padding: 0px 35px;
}
.aobox h6{
margin-bottom:0px;
font-size:14px;
}
.aobox img{
width:32px;
}
/*==============================================*/
.listbog{
width:100%;
position:relative;
}
.listbog .thumbnail{
width:100%;
position:relative;
}
.listbog .thumbnail a {
display: block;
border-radius: 10px 10px 0px 0px;
position: relative;
overflow: hidden;
}
.listbog .thumbnail a:after {
content: "";
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.5);
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
visibility: hidden;
opacity: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
}
.listbog .thumbnail a img {
width: 100%;
border-radius: 10px 10px 0px 0px;
-webkit-transition: var(--transition);
transition: var(--transition);
height:200px;
object-fit:cover;
}
.listbog:hover .thumbnail a:after {
visibility: visible;
opacity: 1;
}

.listbog:hover .thumbnail a img {
-webkit-transform: scale(1.1);
-ms-transform: scale(1.1);
transform: scale(1.1);
}

.listbog .content {
background-color: var(--color-black-light);
padding:20px;
border-radius:0px 0px 10px 10px;
position: relative;
}
.listbog .content .read-more-btn {
position: absolute;
top: -40px;
right: 30px;
visibility: hidden;
opacity: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
}
.listbog:hover .content .read-more-btn {
visibility: visible;
opacity: 1;
}
.listbog .content .title {
margin-bottom: 10px;
font-size:16px;
}
.btn-icon-round {
text-align: center;
height: 60px;
width: 60px;
line-height: 60px;
background-color: var(--color-primary);
border-radius: 50%;
color: var(--color-white);
font-size:18px;
display:inline-block;
}
.blog-meta {
display: flex;
flex-wrap: wrap;
/* padding-bottom: ; */
margin: 0;
list-style: none;
padding: 0;
}
.blog-meta li {
position:relative;
margin-right:20px;
display:flex;
align-items:center;
font-size:12px;
}
.blog-meta li:last-child:after {
display: none;
}
.blog-meta li:after {
content: "";
height: 12px;
width: 1px;
background-color: var(--color-black);
position: absolute;
top: 3px;
right: -10px;
}
.blog-meta li a {
color: var(--color-white);
}

.blog-meta li i,
.blog-meta li img {
font-size: 16px;
padding-right: 10px;
position: relative;
color: var(--color-primary);
}
/*==============================================*/

.listbog-details{
width:100%;
position:relative;
}
.listbog-details .thumbnail{
width:100%;
position:relative;
margin-bottom:15px;
}
.listbog-details .thumbnail img{
width:100%;
border-radius:10px;
}
.listbog-details .content{
width:100%;
position:relative;
}
.listbog-details .content h1{
font-size:24px;
margin-bottom:15px;
}
/*==========================================================*/
.contact-address-section {
background: var(--color-gray-dark);
padding: 25px;
border-radius: 5px;
margin-top: 0px;
}

.address-contact {
width: 100%;
position: relative;
}

.address-contact ul {
margin: 0;
padding: 0;
list-style: none;
}

.address-contact ul li {
width: 100%;
display: inline-block;
position: relative;
margin-top: 10px;
}

.address-contact ul li a {
width: 100%;
display: flex;
color: #333;
align-items: center;
line-height: 24px;
}

.address-contact ul li a span {
flex: 1;
margin-left: 10px;
}

.address-contact ul li i {
position: relative;
font-size: 14px;
border: 1px solid var(--color-primary);
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border-radius: 50%;
background: var(--color-primary);
color: #fff;
}

.address-contact ul li a:hover {
color: var(--color-primary) !important;
}

/*==========================================================*/
/*==========================================================*/
/*==============================================*/
.mfb-section{
width:100%;
position:relative;
border-top:1px solid var(--color-gray-dark);
background:var(--color-gray);
position:fixed;
bottom:0;
left:0;
z-index:99;
}
.mfb-section .mfb-section-inner{
display:flex;
align-items:center;
}
.mfb-box{
width:100%;
position:relative;
display:flex;
align-items:center;
height:50px;
justify-content:center;
cursor:pointer;
}
/*=======================================*/
.sortModal.modal.bottom .modal-dialog {
position: fixed;
margin: auto;
width: 100%;
max-width:100%;
height:auto;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.sortModal.bottom .modal-content{
height: 100%;
overflow-x: auto;
}
.sortModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.sortModal.modal.bottom.fade .modal-dialog {
bottom: auto;
-webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
transition: opacity 0.3s linear, bottom 0.3s ease-out;
}
.sortModal.modal.bottom.fade.show .modal-dialog {
bottom: 0;
}
.sortModal .modal-content{
background: var(--color-gray);
border-radius: 0px;
}
.sortModalbox{
width:100%;
position:relative;
padding:15px 15px 15px 15px;
}
.sortModalbox .pop-close{
top: -40px;
right: 5px;
background: transparent;
color: #fff;
opacity: 1;
font-size: 20px;
position: fixed;
}
.sortModalbox ul{
margin:0;
padding:0;
list-style:none;
}
.sortModalbox ul li{
display:flex;
align-items:center;
justify-content:space-between;
height:30px;
}
.sortModalbox ul li input[type=checkbox] {
position: relative;
border-radius: 2px;
background: #bebebe;
cursor: pointer;
line-height: 0;
margin: 0 .6em 0 0;
outline: 0;
padding: 0 !important;
vertical-align: text-top;
height:18px;
width: 18px;
-webkit-appearance: none;
opacity: .5;
}
.sortModalbox ul li input[type=checkbox]:hover {
opacity: 1;
}
.sortModalbox ul li input[type=checkbox]:checked {
background-color: #000;
opacity: 1;
}
.sortModalbox ul li input[type=checkbox]:before {
content: '';
position: absolute;
right: 50%;
top: 50%;
width: 4px;
height: 10px;
border: solid #bebebe;
border-width: 0 2px 2px 0;
margin: 0;
transform: rotate(45deg) translate(-50%, -50%);
z-index: 2;
}

/*==============================================*/
.pbqty{
display: inline-flex;
width: 90px;
height: 45px;
border: 1px solid var(--color-primary);
margin-right: 10px;
justify-content: space-between;
border-radius:3px;
}
.pbqty button{
padding: 0;
width: 50px !important;
min-width:30px !important;
border: 1px solid transparent;
font-size: 16px;
color: #000;
background-color: transparent;
cursor: pointer;
-webkit-appearance: none;
}
.pbqty input{
background:var(--color-white);
color:#000;
border:1px solid var(--color-white);
text-align:center;
width:100%;
height:43px;
}
.pbqty .quantity {
-moz-appearance: textfield;
max-width: 48px;
}
/*==============================================*/
/*==============================================*/
.stock-text{
width:100%;
display:flex;
align-items:center;
margin-bottom:10px;
font-size:14px;
}
.stock-text span{
margin-left:10px;
}
.stock-text .instock{
color: #388e3c;
}
.stock-text .outofdtock{
color: red;
}
.stock-text-p{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(255,255,255, 0.5);
display: flex;
align-items: center;
justify-content: center;
color: red;
border-radius: 10px;
z-index:9;
}
/*==============================================*/
/*==============================================*/
.nav-item.active {
color: #d2ac67;
}
/*==============================================*/
/*==============================================*/
.cancelModal .pop-close{
background: transparent;
font-size:20px;
top: 5px;
}
/*==============================================*/
/*=======================================*/
.filterModal.modal.bottom .modal-dialog {
position: fixed;
margin: auto;
width: 100%;
max-width:100%;
height:100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.filterModal.bottom .modal-content{
height: 100%;
overflow-x: auto;
}
.filterModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.filterModal.modal.bottom.fade .modal-dialog {
bottom: auto;
-webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
transition: opacity 0.3s linear, bottom 0.3s ease-out;
}
.filterModal.modal.bottom.fade.show .modal-dialog {
bottom: 0;
}
.filterModal .modal-content{
background: var(--color-white);
border-radius: 0px;
}
.filterModal .pop-close{
top: 0;
right: 0;
background: transparent;
color: #fff;
opacity: 1;
font-size: 20px;
position: relative;
}
.filterModal-section{
width:100%;
position:relative;
}
.filterModal-section .filterModal-header{
display:flex;
align-items:center;
padding:15px;
justify-content:space-between;
background:var(--color-black-light);
}
.filterModal-section .filterModal-footer{
display:flex;
align-items:center;
justify-content:space-between;
position:fixed;
bottom:0;
left:0;
width:100%;
padding:15px;
background:var(--color-gray);
}
.filterModal-footer span{
width:100%;
text-align:center;
}
/*=======================================*/
/*==============================================*/
.filter_tabs {
position: relative;
width: 100%;
height: 100%;
background:var(--color-white);
margin-top: 1px;
}
.filter_tab {
float: left;
clear: both;
width: 120px;
}
.filter_tab label {
background:var(--color-white);
padding: 10px;
font-size: 14px;
vertical-align: middle;
position: relative;
width:120px;
height: 45px;
display: table-cell;
}
.filter_tab [type=radio] {
display: none;
}
.filter_content {
position: fixed;
top: 50px;
left: 120px;
background: var(--color-gray);
right: 0;
bottom: 52px;
padding: 15px;
overflow:auto;
}
[type=radio]:checked ~ label {
background:var(--color-gray);
z-index: 2;
color:var(--color-primary);
}
[type=radio]:checked ~ label ~ .filter_content {
z-index: 9999;
}
.fiter_content_list{
width:100%;
position:relative;
}
.fiter_content_list ul{
margin:0;
padding:0;
list-style:none;
}
.fiter_content_list ul li{
display:flex;
align-items:center;
justify-content:space-between;
height:30px;
}
.fiter_content_list ul li input[type=checkbox] {
position: relative;
border-radius: 2px;
background: #bebebe;
cursor: pointer;
line-height: 0;
outline: 0;
padding: 0 !important;
vertical-align: text-top;
height:18px;
width: 18px;
-webkit-appearance: none;
opacity: .5;
}
.fiter_content_list ul li input[type=checkbox]:hover {
opacity: 1;
}
.fiter_content_list ul li input[type=checkbox]:checked {
background-color: #000;
opacity: 1;
}
.fiter_content_list ul li input[type=checkbox]:before {
content: '';
position: absolute;
right: 50%;
top: 50%;
width: 4px;
height: 10px;
border: solid #bebebe;
border-width: 0 2px 2px 0;
margin: 0;
transform: rotate(45deg) translate(-50%, -50%);
z-index: 2;
}
/*==============================================*/
.sidemenu{
width:100%;
position:relative;
padding-top:15px;
}
.sidemenu ul{
margin:0;
padding:0;
list-style:none;
}
.sidemenu ul li{
display:inline-block;
width:100%;
position:relative;
}
.sidemenu ul li .sub-menu-inner{
display:flex;
align-items:center;
justify-content:space-between;	
padding:0px 15px;
height:30px;
}
.sub-menu-inner .right{
width: 50px;
text-align: right;
}
.sidemenu ul li a{
-webkit-transition: 0.2s linear;
-moz-transition: 0.2s linear;
-ms-transition: 0.2s linear;
-o-transition: 0.2s linear;
transition: 0.2s linear;
}
.sidemenu ul li ul{
background:var(--color-gray);
}
.sidemenu ul li ul li a{
display:flex;
height:30px;
align-items:center;
padding:0px 15px;
font-size:12px;
}
/* ==============================================*/

/*======================*/
.variationModal.right .modal-dialog {
position: fixed;
margin: auto;
width: 550px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.variationModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.variationModal.right.fade .modal-dialog {
right: -550px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}
.variationModal.right.fade.show .modal-dialog {
right: 0;
}
.variationModal .modal-content{
background: var(--color-white);
border-radius: 0px;
}
.variationModalbox{
width:100%;
position:relative;
padding:25px;
}
.variationModalbox .pop-close{
top: 15px;
right: 15px;
background: var(--color-white);
color: #000;
opacity: 1;
font-size: 20px;
}
.variationModalbox .vm-product{
width:100%;
position:relative;
display:flex;
}
.vm-product .product-media {
position: static;
width: 100px;
margin-right: 15px !important;
}
.vm-product .product-media img {
height: 100%;
border-radius: 5px;
}
.vm-product .product-detail {
flex: 1 1;
margin: 0 2rem 0rem 0;
}
.vm-product .product-name{
font-size:18px;
font-weight:600;
margin-bottom:15px;
}
.vm-product .dvariation{
width: 100%;
display: flex;
align-items: flex-start;
margin-bottom: 0px;
margin-top: 10px;
flex-direction: column;
}
.vm-product .dvariation label{
margin-bottom:10px;
}
.variationModalbox .product-button{
display:flex;
}
.variationModalbox .product-button button{
width:100%;
}
/*=======================================*/

.mvariationModal.modal.bottom .modal-dialog {
position: fixed;
margin: auto;
width: 100%;
max-width:100%;
height:auto;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.modal.bottom .modal-content{
height: 100%;
overflow-x: auto;
}
.mvariationModal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
.mvariationModal.modal.bottom.fade .modal-dialog {
bottom: auto;
-webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
-o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
transition: opacity 0.3s linear, bottom 0.3s ease-out;
}
.mvariationModal.modal.bottom.fade.show .modal-dialog {
bottom: 0;
}

.mvariationModal .modal-content{
background: var(--color-white);
border-radius: 0px;
}
.mvariationModalbox{
width:100%;
position:relative;
padding:15px;
}
.mvariationModalbox .pop-close{
box-sizing: content-box;
width: 1em;
height: 1em;
padding: 0px 5px 7px;
color: #000;
background: transparent;
border: 0;
border-radius: .25rem;
opacity: .5;
position: absolute;
top:1px;
right: 4px;
cursor: pointer;
z-index: 9999;
font-size: 12px;
}

.mvariationModalbox .mvm-product{
width:100%;
position:relative;
display:flex;
}
.mvm-product .product-media {
position: static;
width: 100px;
margin-right: 15px;
min-width: 100px;
}
.mvm-product .product-media img {
height: 100%;
border-radius: 5px;
}
.mvm-product .product-detail {
flex: 1;
margin: 0 2rem 0rem 0;
}
.mvm-product .product-name{
font-size:18px;
font-weight:600;
margin-bottom:15px;
}
.mvm-product .product-price{
font-size:15px;
}
.mvm-product .dvariation{
width: 100%;
display: flex;
align-items: flex-start;
margin-bottom: 0px;
margin-top: 10px;
flex-direction: column;
font-size: 12px;
}
.mvm-product .dvariation label{
margin-bottom:10px;
}
.mvm-product .dvariation-list a {
margin-right: 5px;
}
.mvm-product .dvariation-list .size{
padding:5px 10px;
}
.mvariationModalbox .product-button{
display:flex;
}
.mvariationModalbox .product-button button{
width:100%;
} 
/*=======================================*/
/*============================================== */
.oddetails-item{
display:flex;
align-items:center;
width:100%;
position:relative;
padding:15px;
}
.oddetails-item .oddetails-item-media{
width:50px;
height:50px;
margin-right:15px;
}
.oddetails-item-media img{
width:50px;
height:50px;
border-radius:5px;
object-fit:cover;
}
.oddetails-item .oddetails-item-content{
flex:1;
}
/*==============================================*/
/*==============================================*/
.vsteprocess{
padding:15px 15px 15px 0px; 
}
.StepProgress {
position: relative;
padding-left: 45px;
list-style: none;
}
.StepProgress::before {
display: inline-block;
content: '';
position: absolute;
top: 5px;
left: 25px;
width: 10px;
height: 95%;
border-left: 2px solid #CCC;
}
.StepProgress .StepProgress-item {
position: relative;
counter-increment: list;
}
.StepProgress .StepProgress-item:not(:last-child) {
padding-bottom: 15px;
}
.StepProgress .StepProgress-item::before {
display: inline-block;
content: '';
position: absolute;
left: -20px;
height: 100%;
width: 10px;
top:5px;
}
.StepProgress .StepProgress-item::after {
content: '';
display: inline-block;
position: absolute;
top: 5px;
left: -29px;
width: 20px;
height: 20px;
border: 2px solid #CCC;
border-radius: 50%;
background-color: #FFF;
}
.StepProgress-item.is-done::before {
border-left: 2px solid green;
}
.StepProgress-item.is-done::after {
content: "✔";
font-size: 12px;
color: #fff !important;
text-align: center;
border: 2px solid green;
background-color: green;
display: flex;
align-items: center;
justify-content: center;
}
/*==============================================*/
.catbox {
width: 100%;
position: relative;
text-align: center;
display: block;
}
.catbox .catbox-media {
width: 100%;
overflow: hidden;
position: relative;
margin: 0px auto 10px;
}
.catbox .catbox-media img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
transition: transform 0.3s
}
.catbox .catbox-title {
font-size: 13px;
font-family: var(--font-secondary);
}
.catbox:hover img {
transform: scale(0.9)
}
/*==============================================*/
/*=======================================*/
.addbox {
width: 100%;
position: relative;
display: block;
}
.addbox img {
width: 100%;
}
/*==============ANAND=========================*/
.footer-contact {
width: 100%;
position: relative;
}
.footer-contact ul {
margin: 0;
padding: 0;
list-style: none;
}
.footer-contact ul li {
width: 100%;
display: inline-block;
position: relative;
margin-top: 10px;
}
.footer-contact ul li a {
width: 100%;
display: flex;
align-items: center;
line-height: 24px;
}
.footer-contact ul li a span {
flex: 1;
margin-left: 10px;
}
.footer-contact ul li i {
position: relative;
font-size: 14px;
border: 1px solid var(--color-primary);
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border-radius: 50%;
background: var(--color-primary);
color: #fff;
}
.footer-contact ul li a:hover {
color: var(--color-primary) !important;
}
.paymet-icon {
text-align: right;
}
@media (max-width: 991px) {
footer {
padding: 60px 0px 100px;
}
.footer-social ul li a i {
font-size: 14px;
}
}
/*=========================================================*/
.filterselect{
width: 100%;
display: flex;
align-items: center;
position: relative;
margin-top: 20px;
}
.filterselect .resetbutton{
position: absolute;
right: 0;
top:0;
background: #D2AC67;
color:#fff;
border-radius: 50px;
padding: 5px 15px;
border: 1px solid #D2AC67;
font-size: 12px;
}
.filterselect .filterselectlist{
width: 100%;
position: relative;
padding-right: 100px;
}
.filterselectlist ul{
margin: -5px;
padding: 0;
list-style: none;
display: flex;
align-items: center;
flex-wrap: wrap;
}
.filterselectlist ul li{
cursor: pointer;
display: flex;
align-items: center;
border-radius: 50px;
border: 1px solid #eee;
padding: 3px 10px;
font-size: 12px;
font-weight: 500;
margin: 5px;
color:#D2AC67;
}
.filterselectlist ul li i{
margin-left: auto;
margin-left: 10px;
}
/*==============================================*/
/*=====================================================*/
.product-wvideo{
width: 100%;
position: relative;
transition: 0.3s;
overflow: hidden;
color: #333;
border-radius:0px;
background:#fff;
}
.product-wvideo .product-media video{
width:100%;
height:450px;
border-radius:0px;
object-fit: cover;
}
.product-wvideo .product-details{
padding-top:5px;
}
.product-wvideo .ratings-container{
margin-bottom:0px;
}
/*=====================================================*/
.swiper-slide video{
width:100%;
border-radius:10px;
}
.mySwiperv {
padding:10px 0px;
}
.mySwiperv .swiper-button-prev {
left: 50% !important;
top: 0% !important;
transform: rotate(90deg);
transform-origin: left center;
background:var(--color-primary);
}
.mySwiperv .swiper-button-next {
left: 50% !important;
bottom: 0% !important;
top: auto !important;
transform: rotate(90deg);
transform-origin: left center;
background:var(--color-primary);
}
.swiper-button-prev::after, .swiper-button-next::after{
font-size:16px;
}
/*=================================================*/
.skeleton-horizontal {
display: flex;
align-items: center; 
}

.skeleton-circle {
overflow: hidden; 
margin-right: 10px; 
}

/*=================================================*/
.pop-close {
box-sizing: content-box;
width: 1em;
height: 1em;
padding: .25em .25em;
color: #000;
background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
border: 0;
border-radius: .25rem;
opacity: .5;
position: absolute;
top:15px;
right: 15px;
cursor: pointer;
z-index: 9999;
}
/*==========================================================*/
/*==========================================================*/
.cartSection{
width: 100%;
position: absolute;
height: 100%;
left: 0;
top:70px;
}
.cartSection::after {
content: '';
position: fixed;
width: 42.5%;
height: 100%;
background: var(--color-gray-100);
right: 0;
top: 0;
}
.cartSection .container, .cartSection .container-fluid{
display: flex;
height: 100%;
z-index: 9;
position: relative;
}
.cartSection .left{
width: 60%;
position: relative;
}
.cartSection .right{
width: 40%;
position: sticky;
}
.checkoutProduct{
width: 100%;
position: relative;
display: flex;
margin-bottom: 15px;
gap: 15px;
}
.checkoutProduct .checkoutProductMedia{
width: 75px;
height: 75px;
overflow: hidden;
border-radius: 5px;
}
.checkoutProduct .checkoutProductMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.checkoutProductContent{
position: relative;
flex: 1;
}
.checkoutProductContent .title{
font-size: 13px;
margin-bottom: 5px;
}
.checkoutProductContent .cpcvar{
display: flex;
align-items: center;
gap: 15px;
}
.checkoutProductContent .price {
display: flex;
align-items: center;
gap: 10px;
font-weight: 600;
font-size: 14px;
}
.checkoutProductContent .price .new-price {
text-decoration: none;
}
.checkoutProductContent .price .old-price {
color: #aaa;
font-size: 14px;
}
.cartSectionCoupon{
display: flex;
align-items: center;
gap: 10px;
}
.cartSectionCoupon input{
width: 100%;
position: relative;
height: 55px;
border-radius: 5px;
border: 1px solid var(--color-gray-200);
padding: 0px 15px;
}
.cartSectionCoupon button{
height: 55px;
width: 100px;
background: var(--color-primary);
border: 1px solid var(--color-primary);
border-radius: 5px;
}
.cartSectionPrice{
margin: 0;
padding: 0;
list-style: none;
}
.cartSectionPrice li{
display: flex;
align-items: center;
justify-content: space-between;
height: 30px;
}
.cartSectionPrice li.bigrow{
font-size: 16px;
font-weight: 600;
}
.cartSectionTitle{
display: flex;
align-items: center;
justify-content: space-between;
}
/*==========================================================*/
.flotting-group{
width: 100%;
position: relative;
}
.flotting-group label{
    position: absolute;
  left: 8px;
  font-size: 13.2px;
  top: 8px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: top 0.5s, left 0.5s, font 0.5s;
  transition: top 0.5s, left 0.5s, font 0.5s;
}
.flotting-group .label{
position: absolute;
pointer-events: none;
top: -8px;
left: 10px;
background-color: #FFF;
padding: 0px 5px;
font-size: 10px;
visibility: visible;
opacity: 1;
font-weight: 500;
text-transform: uppercase;
color:var(--color-primary);
}
.flotting-group input {
background: #fff;
border: 1px solid var(--color-gray-200);
box-sizing: border-box;
border-radius: 4px;
padding: 0 15px;
display: block;
width: 100%;
height: 50px;
font-size: 14px;
line-height: 22px;
box-shadow: none;
}
.flotting-group > .form-control, .flotting-group > .form-select {
height: calc(3rem + 2px);
line-height: 1.25;
font-size:14px;
}
.checkboxgroup{
display: flex;
align-items: center;
gap: 10px;
}
.checkboxgroup input[type="checkbox"]{
width: auto !important;
height: auto !important;
}
.form-control:disabled {
    background-color:var(--color-white) !important;
    opacity: 1;
  }
/*==========================================================*/
.home-slider{
position: relative;
}
.home-slider .swiper-arrow{
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}
.home-slider .swiper-arrow .prev-arrow{
left: 10px;
position: absolute;
}
.home-slider .swiper-arrow .next-arrow{
right: 10px;
position: absolute;
}
.swiper-arrow img{
height: auto !important;
}
.mhome-slider .swiper-arrow{
display: flex;
align-items: center;
justify-content: space-between;
position: absolute;
width: 100%;
top: 50%;
z-index: 9;
left: 0;
}
.mhome-slider .swiper-arrow .prev-arrow{
left: 10px;
position: absolute;
}
.mhome-slider .swiper-arrow .next-arrow{
right: 10px;
position: absolute;
}
/*==========================================================*/
.category{
position: relative;
width: 100%; 
text-align: center;
}
.category .category-media{
width: 100%;
position: relative;
overflow: hidden;
}
.category .category-media img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}
.category .categorytitle{
font-size: 14px;
margin-top: 10px;
}
.category .categorytitle a{
display: inline-block;
}
@media (max-width: 991px) {
.category .categorytitle{
font-size: 12px;
margin-top: 10px;
}   
}
/*==========================================================*/
.cartAddressbox{
  border: 1px solid var(--color-gray-200);
  padding: 15px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  padding-left: 35px;
  cursor: pointer;
}
.cartAddressbox.active{
  border: 1px solid var(--color-gray-200);
  background: #f9f9f9;
}
.cartAddressbox input[type="radio"]{
  position: absolute;
  left: 12px;
  top:19px;
}
/*==========================================================*/
.ordersummaryToggle{
  width: 100%;
  position: relative;
}
.ordersummaryToggle .ordersummaryToggleHed{
  padding: 15px;
  border-bottom: 4px solid var(--color-gray);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: var(--color-white);
}
.ordersummaryToggle .ordersummaryTogglecont{
  background: #f9f9f9;
}
/*==========================================================*/
.cardPanel{
  width: 100%;
  position: relative;
  border-bottom: 4px solid var(--color-gray);
}
.cardPanel .cardPanelBody{
  padding: 15px;
}
/*==========================================================*/
.paymentoptionbox{
  width: 100%;
  position: relative;
  height: 60px;
  border: 1px solid var(--color-gray-200);
  border-radius: 7px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paymentoptionbox .paymentoptionboxIcon{
  display: flex;
  align-items: center;
}
.paymentoptionbox input[type=radio] {
  background-color: transparent;
  border: 0.0625em solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: inset 0 0 0 0 white;
  cursor: pointer;
  font: inherit;
  height: 24px;
  outline: none;
  width: 24px;
}
.paymentoptionbox input[type=radio]:checked {
  background-color: white;
  box-shadow: inset 0 0 0 0.1875em #faa850;
}
.paymentcardbox{
  width: 100%;
  position: relative;
  border: 1px solid var(--color-gray-200);
  border-radius: 7px;
  padding:15px;
}
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
.section-gap-large {
padding: 80px 0px;
}
.section-gap-medium {
padding: 60px 0px;
}
.section-gap-small {
padding: 30px 0px;
}
.sectiontop-gap-large {
padding-top: 80px;
}
.sectiontop-gap-medium {
padding-top: 60px;
}
.sectiontop-gap-small {
padding-top: 30px;
}
.sectionbottom-gap-large {
padding-bottom: 80px;
}
.sectionbottom-gap-medium {
padding-bottom: 60px;
}
.sectionbottom-gap-small {
padding-bottom: 30px;
}
@media (max-width: 991px){
.sectiontop-gap-small {
padding-top: 15px;
}
.sectionbottom-gap-small {
padding-bottom: 15px;
}
.sectiontop-gap-medium {
padding-top: 30px;
}
.sectionbottom-gap-medium {
padding-bottom: 30px;
}
}
.cols-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-3>* {
max-width: 33.3333% !important;
flex: 0 0 33.3333%
}
.cols-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
@media (min-width:1200px) {
.cols-xl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1600px) {
.cols-xxl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xxl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xxl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xxl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xxl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xxl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xxl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xxl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1200px) {
.col-xl-5col {
position: relative;
flex: 0 0 20%;
max-width: 20%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col2 {
position: relative;
flex: 0 0 40%;
max-width: 40%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col4 {
position: relative;
flex: 0 0 80%;
max-width: 80%;
padding-right: 10px;
padding-left: 10px
}
}
.order-first {
order: -1
}
.order-last {
order: 9
}
@media (min-width:576px) {
.order-sm-auto {
order: 0
}
.order-sm-first {
order: -1
}
.order-sm-last {
order: 9
}
}
@media (min-width:768px) {
.order-md-auto {
order: 0
}
.order-md-first {
order: -1
}
.order-md-last {
order: 9
}
}
@media (min-width:992px) {
.order-lg-auto {
order: 0
}
.order-lg-first {
order: -1
}
.order-lg-last {
order: 9
}
}
@media (min-width:1200px) {
.order-xl-auto {
order: 0
}
.order-xl-first {
order: -1
}
.order-xl-last {
order: 9
}
}
/*==========================================================*/
/*===================ANAND CSS=========================================================*/
/*=======================================*/
.testimonial .testimonial-grid {
width: 100%;
position: relative;
transition: 0.3s;
overflow: hidden;
color: #000;
padding: 30px;
min-height: 315px;
border-radius: 10px;
background: var(--color-gray);
/* height: 250px; */
}

.testimonial .testimonial-grid .thumbnail img {
width: 90px;
border-radius: 55px;
margin-bottom: 15px;
}
#portal{
position: absolute;
z-index: 999;
}
/*============================================================================*/
.d-icon-star-full.fill {
color: #ffa41c;
}
/*============================================================================*/
/*============================================================================*/
